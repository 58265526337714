import React, { useState, useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import Header from "../components/Header/Header";
import QuickSOAP from "./QuickSOAP";
import {
  FaCheck,
  FaArrowRight,
  FaCalendar,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import "../styles/Scribe.css";
import { useDoctor } from "../contexts/DoctorContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usePatientStore } from "../stores/patientStore";
import PatientListItem from "../components/Scribe/ScribePatientListItem";
import ReactConfetti from "react-confetti";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../config/firebase";

interface ScribeProps {
  refreshFirestoreData: () => Promise<void>;
  refreshTrigger: number;
}

interface Patient {
  id: string;
  name: string;
  breed: string;
  status?: "upcoming" | "inProgress" | "complete";
  appointmentTime?: string;
  appointmentType?: string;
  age?: string;
  sex?: string;
  weight?: string;
  pims_id?: string;
  color?: string;
  species?: string;
  missionBrief?: any;
}

interface Appointment {
  patient_id: string;
  time: string;
  type: string;
  patient: string;
  duration: number;
  appointmentColor?: string;
  status?: "upcoming" | "inProgress" | "complete";
  timeForSort?: Date;
  isUnassigned?: boolean;
}

interface UserData {
  user?: {
    settings?: {
      appointmentTypes?: AppointmentTypes;
    };
    missionBrief?: {
      [doctorId: string]: {
        appointments: Array<{
          appointmentTime?: {
            time: string;
            appointmentType: string;
            duration: number;
          };
          patientData?: {
            patientId: string;
            name: string;
            species: string;
            breed: string;
            age: string;
            sex: string;
            missionBrief?: any;
          };
          appointmentColor?: string;
          status?: "upcoming" | "inProgress" | "complete";
        }>;
      };
    };
  };
}

interface AppointmentTypeSettings {
  types: string[];
  color: string;
}

interface AppointmentTypes {
  [key: string]: AppointmentTypeSettings;
}

const Scribe: React.FC<ScribeProps> = ({
  refreshFirestoreData,
  refreshTrigger,
}) => {
  const { selectedDoctor } = useDoctor();
  const {
    selectedPatient,
    selectedHistoricalNote,
    patientsData,
    progressStats,
    setSelectedPatient,
    setSelectedHistoricalNote,
    setPatientsData,
    updatePatient,
    updateProgressStats,
    moveToInProgress,
    moveToComplete,
    clearSelections,
  } = usePatientStore();

  const [userData] = useState<UserData>(() => {
    const data = JSON.parse(localStorage.getItem("userData") || "{}");
    return data;
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showConfetti, setShowConfetti] = useState(false);
  const [completionCounter, setCompletionCounter] = useState(() => {
    const counter = Number(localStorage.getItem("completionCounter") || "0");
    return counter;
  });
  const [previousProgress, setPreviousProgress] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    document.body.style.userSelect = "none";
    return () => {
      document.body.style.userSelect = "auto";
    };
  }, []);

  useEffect(() => {
    const doctorId = selectedDoctor?.value;
    if (doctorId) {
      const storedUserData = JSON.parse(
        localStorage.getItem("userData") || "{}"
      );
      const doctorData = storedUserData.user?.missionBrief?.[doctorId];

      if (doctorData?.appointments) {
        // Transform appointments to match the expected format
        const transformedAppointments = doctorData.appointments.map(
          async (apt: any) => {
            const timestamp = apt.appointmentTime;
            const date = new Date(timestamp.seconds * 1000);
            const formattedTime = date
              .toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })
              .toLowerCase();

            // Get the status from dailyNotes collection
            try {
              const clinicId = storedUserData.user?.clinicId;
              const userId = auth.currentUser?.uid;
              const dateStr = selectedDate.toISOString().split("T")[0];

              if (clinicId && userId && apt.patientData?.pimsId) {
                const noteRef = doc(
                  db,
                  `clinics/${clinicId}/users/${userId}/scribe/${dateStr}/dailyNotes/${apt.patientData.pimsId}`
                );
                const noteDoc = await getDoc(noteRef);
                if (noteDoc.exists()) {
                  return {
                    patient_id: apt.patientData?.patientId,
                    patient: apt.patientData?.name || "",
                    time: formattedTime,
                    type: apt.appointmentType,
                    duration: apt.duration || 0,
                    appointmentColor: apt.appointmentColor,
                    status: noteDoc.data().status || "upcoming",
                  };
                }
              }
            } catch (error) {
              console.error("Error fetching note status:", error);
            }

            // Default if no status found in dailyNotes
            return {
              patient_id: apt.patientData?.patientId,
              patient: apt.patientData?.name || "",
              time: formattedTime,
              type: apt.appointmentType,
              duration: apt.duration || 0,
              appointmentColor: apt.appointmentColor,
              status: "upcoming",
            };
          }
        );

        // Wait for all status checks to complete
        Promise.all(transformedAppointments).then((resolvedAppointments) => {
          // Transform patients data with additional fields
          const newPatientsData = doctorData.appointments.reduce(
            (acc: any, apt: any) => {
              if (apt.patientData) {
                const timestamp = apt.appointmentTime;
                const date = new Date(timestamp.seconds * 1000);
                const formattedTime = date
                  .toLocaleTimeString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                  })
                  .toLowerCase();

                const matchingAppointment = resolvedAppointments.find(
                  (a) => a.patient_id === apt.patientData.patientId
                );

                acc[apt.patientData.patientId] = {
                  id: apt.patientData.patientId,
                  name: apt.patientData.name,
                  species: apt.patientData.species,
                  breed: apt.patientData.breed,
                  age: apt.patientData.age,
                  sex: apt.patientData.sex,
                  appointmentTime: formattedTime,
                  appointmentType: apt.appointmentType,
                  missionBrief: apt.patientData.missionBrief,
                  status: matchingAppointment?.status || "upcoming",
                  pims_id: apt.patientData.pimsId,
                  weight: apt.patientData.weight,
                  color: apt.appointmentColor,
                };
              }
              return acc;
            },
            {}
          );

          setPatientsData(newPatientsData);
          updateProgressStats(resolvedAppointments, newPatientsData);
        });
      }
    }
  }, [
    selectedDoctor,
    refreshTrigger,
    setPatientsData,
    updateProgressStats,
    selectedDate,
  ]);

  // Update the confetti effect
  useEffect(() => {
    if (progressStats.percentage === 100 && completionCounter === 0) {
      console.log("Triggering confetti!");
      setShowConfetti(true);
      setCompletionCounter(1);
      localStorage.setItem("completionCounter", "1");
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    } else if (progressStats.percentage < 100 && completionCounter === 1) {
      console.log("Resetting counter");
      setCompletionCounter(0);
      localStorage.setItem("completionCounter", "0");
    }
  }, [progressStats.percentage, completionCounter]);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId: patientId } = result;

    // Remove drag-over classes
    const columns = document.querySelectorAll(".kanban-section");
    columns.forEach((column) => {
      column.classList.remove("drag-over");
    });

    document.body.style.cursor = "auto";

    if (!destination || !patientId) return;

    const doctorId = selectedDoctor?.value;
    if (!doctorId) return;

    const newStatus = destination.droppableId as
      | "upcoming"
      | "inProgress"
      | "complete";
    const patient = patientsData[patientId];

    // Update local state immediately
    const updatedPatient: Patient = {
      ...patient,
      status: newStatus,
    };

    const newPatientsData: { [key: string]: Patient } = {
      ...patientsData,
      [patientId]: updatedPatient,
    };

    // Update local state immediately
    setPatientsData(newPatientsData);

    // Update localStorage immediately with the new status
    const storedUserData = JSON.parse(localStorage.getItem("userData") || "{}");
    if (storedUserData.user?.missionBrief?.[doctorId]?.appointments) {
      const updatedAppointments = storedUserData.user.missionBrief[
        doctorId
      ].appointments.map((apt: any) => {
        if (apt.patientData?.patientId === patientId) {
          return {
            ...apt,
            status: newStatus,
            patientData: {
              ...apt.patientData,
              status: newStatus,
            },
          };
        }
        return apt;
      });

      const updatedUserData = {
        ...storedUserData,
        user: {
          ...storedUserData.user,
          missionBrief: {
            ...storedUserData.user.missionBrief,
            [doctorId]: {
              ...storedUserData.user.missionBrief[doctorId],
              appointments: updatedAppointments,
            },
          },
        },
      };
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
    }

    // Update progress stats immediately
    const transformedAppointments = Object.values(newPatientsData).map(
      (patient) => ({
        patient_id: patient.id,
        patient: patient.name,
        time: patient.appointmentTime || "",
        type: patient.appointmentType || "",
        duration: 0,
        appointmentColor: "#A5B5BD",
        status: patient.status || "upcoming",
      })
    );

    updateProgressStats(transformedAppointments, newPatientsData);

    // Only select the patient if they're being moved INTO "inProgress"
    if (destination.droppableId === "inProgress") {
      const appointment = transformedAppointments.find(
        (apt) => apt.patient_id === patientId
      );
      if (appointment) {
        handlePatientSelect(updatedPatient, appointment);
      }
    }

    // Update Firestore with new path
    try {
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const clinicId = userData?.user?.clinicId;
      const userId = auth.currentUser?.uid;

      if (!clinicId || !userId || !patient.pims_id) {
        throw new Error("Missing required IDs");
      }

      const dateStr = selectedDate.toISOString().split("T")[0]; // Format: YYYY-MM-DD
      const noteRef = doc(
        db,
        `clinics/${clinicId}/users/${userId}/scribe/${dateStr}/dailyNotes/${patient.pims_id}`
      );

      await updateDoc(noteRef, {
        status: newStatus,
      });
    } catch (error) {
      console.error("Error updating status in Firestore:", error);
    }
  };

  const onDragStart = () => {
    // Remove the global cursor style change
  };

  const onDragUpdate = (update: any) => {
    const { destination } = update;

    const containers = document.querySelectorAll(".kanban-cards-container");
    containers.forEach((container) => {
      container.classList.remove("drag-over");
    });

    if (destination) {
      const destinationEl = document.querySelector(
        `.${destination.droppableId} .kanban-cards-container`
      );
      destinationEl?.classList.add("drag-over");
    }
  };

  const getPatientsByStatus = (
    status: "upcoming" | "inProgress" | "complete"
  ): Appointment[] => {
    const doctorId = selectedDoctor?.value;
    if (!doctorId) return [];

    const storedUserData = JSON.parse(localStorage.getItem("userData") || "{}");
    const doctorData = storedUserData.user?.missionBrief?.[doctorId];

    if (!doctorData?.appointments) return [];

    const appointmentsArray = Array.isArray(doctorData.appointments)
      ? doctorData.appointments
      : Object.values(doctorData.appointments);

    const transformedAppointments = appointmentsArray
      .filter((apt: any) => {
        return apt.appointmentTime;
      })
      .map((apt: any) => {
        // Convert Firestore Timestamp to readable time
        const timestamp = apt.appointmentTime;
        const date = new Date(timestamp.seconds * 1000);
        const formattedTime = date
          .toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          })
          .toLowerCase();

        return {
          patient_id: apt.patientData?.patientId,
          patient: apt.patientData?.name || "",
          time: formattedTime,
          timeForSort: date,
          type: apt.appointmentType,
          duration: apt.duration || 0,
          appointmentColor: apt.appointmentColor || "#A5B5BD",
          status:
            patientsData[apt.patientData?.patientId]?.status || "upcoming",
          isUnassigned: formattedTime === "12:00am",
        };
      })
      .filter((app: Appointment) => {
        return (
          app.type !== "Block" &&
          app.type !== "Free Time" &&
          app.patient_id !== "0" &&
          app.patient_id !== undefined &&
          app.status === status
        );
      })
      .sort((a: any, b: any) => {
        // Put unassigned appointments (12:00am) at the end
        if (a.isUnassigned && !b.isUnassigned) return 1;
        if (!a.isUnassigned && b.isUnassigned) return -1;

        // Sort assigned appointments by time
        return a.timeForSort.getTime() - b.timeForSort.getTime();
      });

    return transformedAppointments;
  };

  const handlePatientSelect = (patient: Patient, appointment: Appointment) => {
    if (selectedPatient?.id === appointment.patient_id) {
      // Allow deselection for any status by clearing selections
      clearSelections();
    } else {
      if (patient.status === "complete") {
        const updatedPatient: Patient = {
          ...patient,
          appointmentTime: appointment.time,
          appointmentType: appointment.type,
          id: appointment.patient_id,
          pims_id: patient.pims_id,
          weight: patient.weight,
          color: appointment.appointmentColor,
        };
        setSelectedPatient(updatedPatient);
        setSelectedHistoricalNote(updatedPatient);
      } else {
        setSelectedHistoricalNote(null);
        const updatedPatient: Patient = {
          ...patient,
          appointmentTime: appointment.time,
          appointmentType: appointment.type,
          id: appointment.patient_id,
          pims_id: patient.pims_id,
          weight: patient.weight,
          color: appointment.appointmentColor,
        };
        setSelectedPatient(updatedPatient);
      }
    }
  };

  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  };

  const handlePreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    if (newDate >= getMinDate()) {
      setSelectedDate(newDate);
    }
  };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    if (newDate <= new Date()) {
      setSelectedDate(newDate);
    }
  };

  const handleStartNote = () => {
    const doctorId = selectedDoctor?.value;
    if (!doctorId) return;

    if (selectedPatient && selectedPatient.status !== "inProgress") {
      // Move patient to in-progress
      const updatedPatient: Patient = {
        ...selectedPatient,
        status: "inProgress" as const,
      };

      // Update patients data with explicit typing
      const newPatientsData: { [key: string]: Patient } = {
        ...patientsData,
        [selectedPatient.id]: updatedPatient,
      };

      // Update local state
      setPatientsData(newPatientsData);

      // Update localStorage
      const storedUserData = JSON.parse(
        localStorage.getItem("userData") || "{}"
      );
      if (storedUserData.user?.missionBrief?.[doctorId]?.appointments) {
        const updatedAppointments = storedUserData.user.missionBrief[
          doctorId
        ].appointments.map((apt: any) => {
          if (apt.patientData?.patientId === selectedPatient.id) {
            return {
              ...apt,
              status: "inProgress",
              patientData: {
                ...apt.patientData,
                status: "inProgress",
              },
            };
          }
          return apt;
        });

        const updatedUserData = {
          ...storedUserData,
          user: {
            ...storedUserData.user,
            missionBrief: {
              ...storedUserData.user.missionBrief,
              [doctorId]: {
                ...storedUserData.user.missionBrief[doctorId],
                appointments: updatedAppointments,
              },
            },
          },
        };
        localStorage.setItem("userData", JSON.stringify(updatedUserData));
      }

      // Update progress stats
      const transformedAppointments = Object.values(newPatientsData).map(
        (patient) => ({
          patient_id: patient.id,
          patient: patient.name,
          time: patient.appointmentTime || "",
          type: patient.appointmentType || "",
          duration: 0,
          appointmentColor: patient.color || "#A5B5BD",
          status: patient.status || "upcoming",
        })
      );

      updateProgressStats(transformedAppointments, newPatientsData);
    }
  };

  const handleStatusChange = async (
    patientId: string,
    newStatus: "upcoming" | "inProgress" | "complete"
  ) => {
    const doctorId = selectedDoctor?.value;
    if (!doctorId) return;

    const patient = patientsData[patientId];

    // Update local state immediately
    const updatedPatient: Patient = {
      ...patient,
      status: newStatus,
    };

    const newPatientsData: { [key: string]: Patient } = {
      ...patientsData,
      [patientId]: updatedPatient,
    };

    // Update local state
    setPatientsData(newPatientsData);

    // Update localStorage
    const storedUserData = JSON.parse(localStorage.getItem("userData") || "{}");
    if (storedUserData.user?.missionBrief?.[doctorId]?.appointments) {
      const updatedAppointments = storedUserData.user.missionBrief[
        doctorId
      ].appointments.map((apt: any) => {
        if (apt.patientData?.patientId === patientId) {
          return {
            ...apt,
            status: newStatus,
            patientData: {
              ...apt.patientData,
              status: newStatus,
            },
          };
        }
        return apt;
      });

      const updatedUserData = {
        ...storedUserData,
        user: {
          ...storedUserData.user,
          missionBrief: {
            ...storedUserData.user.missionBrief,
            [doctorId]: {
              ...storedUserData.user.missionBrief[doctorId],
              appointments: updatedAppointments,
            },
          },
        },
      };
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
    }

    // Update Firestore with new path
    try {
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const clinicId = userData?.user?.clinicId;
      const userId = auth.currentUser?.uid;

      if (!clinicId || !userId || !patient.pims_id) {
        throw new Error("Missing required IDs");
      }

      const dateStr = selectedDate.toISOString().split("T")[0]; // Format: YYYY-MM-DD
      const noteRef = doc(
        db,
        `clinics/${clinicId}/users/${userId}/scribe/${dateStr}/dailyNotes/${patient.pims_id}`
      );

      await updateDoc(noteRef, {
        status: newStatus,
      });
    } catch (error) {
      console.error("Error updating status in Firestore:", error);
    }

    // Update progress stats
    const transformedAppointments = Object.values(newPatientsData).map(
      (patient) => ({
        patient_id: patient.id,
        patient: patient.name,
        time: patient.appointmentTime || "",
        type: patient.appointmentType || "",
        duration: 0,
        appointmentColor: "#A5B5BD",
        status: patient.status || "upcoming",
      })
    );

    updateProgressStats(transformedAppointments, newPatientsData);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="scribe-container">
        {showConfetti && (
          <ReactConfetti
            width={windowSize.width}
            height={windowSize.height}
            recycle={false}
            numberOfPieces={777}
            style={{ position: "fixed", top: 0, left: 0, zIndex: 9999 }}
          />
        )}
        <div className="progress-container">
          <h2>Daily Progress</h2>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${progressStats.percentage}%` }}
            />
          </div>
          <div className="progress-stats">
            <span>
              {progressStats.completed} of {progressStats.total} notes completed
            </span>
            <span>{progressStats.percentage.toFixed(1)}%</span>
          </div>
        </div>

        <div className="content-container">
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
          >
            <div className="kanban-container">
              <div className="col-span-3 w-full flex items-center justify-between px-4 py-3 bg-white border-b">
                <button
                  onClick={handlePreviousDay}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  disabled={selectedDate <= getMinDate()}
                >
                  <FaChevronLeft
                    className={
                      selectedDate <= getMinDate()
                        ? "text-gray-300"
                        : "text-gray-600"
                    }
                  />
                </button>

                <div className="relative flex items-center gap-2">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date: Date | null) =>
                      date && setSelectedDate(date)
                    }
                    dateFormat="MMMM d, yyyy"
                    minDate={getMinDate()}
                    maxDate={new Date()}
                    customInput={
                      <div className="flex items-center gap-2">
                        <FaCalendar className="text-gray-500" />
                        {selectedDate.toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </div>
                    }
                  />
                </div>

                <button
                  onClick={handleNextDay}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  disabled={selectedDate >= new Date()}
                >
                  <FaChevronRight
                    className={
                      selectedDate >= new Date()
                        ? "text-gray-300"
                        : "text-gray-600"
                    }
                  />
                </button>
              </div>
              <div className="kanban-columns-container">
                {(["upcoming", "inProgress", "complete"] as const).map(
                  (status) => (
                    <div className={`kanban-section ${status}`} key={status}>
                      <div className="kanban-title">
                        {status === "upcoming" && "Upcoming"}
                        {status === "inProgress" && "In Progress"}
                        {status === "complete" && "Complete"}
                        <span className="kanban-count">
                          {getPatientsByStatus(status).length}
                        </span>
                      </div>

                      <Droppable droppableId={status}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={`kanban-cards-container ${
                              snapshot.isDraggingOver ? "drag-over" : ""
                            }`}
                          >
                            {status === "upcoming" ? (
                              <>
                                {/* Scheduled Appointments */}
                                {getPatientsByStatus(status).some(
                                  (apt) => !apt.isUnassigned
                                ) && (
                                  <div className="appointment-group">
                                    <div className="appointment-group-header">
                                      Scheduled Appointments:{" "}
                                      {
                                        getPatientsByStatus(status).filter(
                                          (apt) => !apt.isUnassigned
                                        ).length
                                      }
                                    </div>
                                    {getPatientsByStatus(status)
                                      .filter(
                                        (appointment) =>
                                          !appointment.isUnassigned
                                      )
                                      .map(
                                        (
                                          appointment: Appointment,
                                          index: number
                                        ) => (
                                          <Draggable
                                            key={appointment.patient_id}
                                            draggableId={appointment.patient_id}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              const props = {
                                                patient: {
                                                  id: appointment.patient_id,
                                                  name: `${
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.name || ""
                                                  } | ${
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.breed || ""
                                                  }`.trim(),
                                                  appointmentTime:
                                                    appointment.time,
                                                  appointmentType:
                                                    appointment.type,
                                                  status:
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.status,
                                                },
                                                status: appointment.status as
                                                  | "upcoming"
                                                  | "inProgress"
                                                  | "complete",
                                                isSelected:
                                                  selectedPatient?.id ===
                                                  appointment.patient_id,
                                                appointmentColor:
                                                  appointment.appointmentColor ||
                                                  "#A5B5BD",
                                              };

                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={
                                                    provided.draggableProps
                                                      .style
                                                  }
                                                >
                                                  <PatientListItem
                                                    {...props}
                                                    onSelect={() =>
                                                      handlePatientSelect(
                                                        patientsData[
                                                          appointment.patient_id
                                                        ],
                                                        appointment
                                                      )
                                                    }
                                                    onMoveToInProgress={() =>
                                                      handleStatusChange(
                                                        appointment.patient_id,
                                                        "inProgress"
                                                      )
                                                    }
                                                    onToggleNote={() =>
                                                      handleStatusChange(
                                                        appointment.patient_id,
                                                        "complete"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        )
                                      )}
                                  </div>
                                )}

                                {/* Unscheduled Appointments */}
                                {getPatientsByStatus(status).some(
                                  (apt) => apt.isUnassigned
                                ) && (
                                  <div className="appointment-group">
                                    <div className="appointment-group-header">
                                      Unscheduled Appointments:{" "}
                                      {
                                        getPatientsByStatus(status).filter(
                                          (apt) => apt.isUnassigned
                                        ).length
                                      }
                                    </div>
                                    {getPatientsByStatus(status)
                                      .filter(
                                        (appointment) =>
                                          appointment.isUnassigned
                                      )
                                      .map(
                                        (
                                          appointment: Appointment,
                                          index: number
                                        ) => (
                                          <Draggable
                                            key={appointment.patient_id}
                                            draggableId={appointment.patient_id}
                                            index={
                                              getPatientsByStatus(
                                                status
                                              ).filter(
                                                (apt) => !apt.isUnassigned
                                              ).length + index
                                            }
                                          >
                                            {(provided, snapshot) => {
                                              const props = {
                                                patient: {
                                                  id: appointment.patient_id,
                                                  name: `${
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.name || ""
                                                  } | ${
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.breed || ""
                                                  }`.trim(),
                                                  appointmentTime:
                                                    appointment.time,
                                                  appointmentType:
                                                    appointment.type,
                                                  status:
                                                    patientsData[
                                                      appointment.patient_id
                                                    ]?.status,
                                                },
                                                status: appointment.status as
                                                  | "upcoming"
                                                  | "inProgress"
                                                  | "complete",
                                                isSelected:
                                                  selectedPatient?.id ===
                                                  appointment.patient_id,
                                                appointmentColor:
                                                  appointment.appointmentColor ||
                                                  "#A5B5BD",
                                              };

                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={
                                                    provided.draggableProps
                                                      .style
                                                  }
                                                >
                                                  <PatientListItem
                                                    {...props}
                                                    onSelect={() =>
                                                      handlePatientSelect(
                                                        patientsData[
                                                          appointment.patient_id
                                                        ],
                                                        appointment
                                                      )
                                                    }
                                                    onMoveToInProgress={() =>
                                                      handleStatusChange(
                                                        appointment.patient_id,
                                                        "inProgress"
                                                      )
                                                    }
                                                    onToggleNote={() =>
                                                      handleStatusChange(
                                                        appointment.patient_id,
                                                        "complete"
                                                      )
                                                    }
                                                  />
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        )
                                      )}
                                  </div>
                                )}
                              </>
                            ) : (
                              // Regular list for other columns
                              getPatientsByStatus(status).map(
                                (appointment: Appointment, index: number) => (
                                  <Draggable
                                    key={appointment.patient_id}
                                    draggableId={appointment.patient_id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      const props = {
                                        patient: {
                                          id: appointment.patient_id,
                                          name: `${
                                            patientsData[appointment.patient_id]
                                              ?.name || ""
                                          } | ${
                                            patientsData[appointment.patient_id]
                                              ?.breed || ""
                                          }`.trim(),
                                          appointmentTime: appointment.time,
                                          appointmentType: appointment.type,
                                          status:
                                            patientsData[appointment.patient_id]
                                              ?.status,
                                        },
                                        status: appointment.status as
                                          | "upcoming"
                                          | "inProgress"
                                          | "complete",
                                        isSelected:
                                          selectedPatient?.id ===
                                          appointment.patient_id,
                                        appointmentColor:
                                          appointment.appointmentColor ||
                                          "#A5B5BD",
                                      };

                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={provided.draggableProps.style}
                                        >
                                          <PatientListItem
                                            {...props}
                                            onSelect={() =>
                                              handlePatientSelect(
                                                patientsData[
                                                  appointment.patient_id
                                                ],
                                                appointment
                                              )
                                            }
                                            onMoveToInProgress={() =>
                                              handleStatusChange(
                                                appointment.patient_id,
                                                "inProgress"
                                              )
                                            }
                                            onToggleNote={() =>
                                              handleStatusChange(
                                                appointment.patient_id,
                                                "complete"
                                              )
                                            }
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                )
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )
                )}
              </div>
            </div>
          </DragDropContext>

          <div className="soap-container">
            <QuickSOAP
              refreshFirestoreData={refreshFirestoreData}
              refreshTrigger={refreshTrigger}
              selectedPatient={selectedPatient}
              selectedHistoricalNote={selectedHistoricalNote}
              onCloseHistoricalNote={() => setSelectedHistoricalNote(null)}
              appointmentTypes={
                userData.user?.settings?.appointmentTypes || {
                  Other: {
                    types: [],
                    color: "#A5B5BD",
                  },
                }
              }
              onStartNote={handleStartNote}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Scribe;
