import React, { useState, useRef, useEffect } from 'react';

interface NewCommentFormProps {
  onSubmit: (content: string) => void;
  onCancel?: () => void;
  autoFocus?: boolean;
}

const NewCommentForm: React.FC<NewCommentFormProps> = ({ 
  onSubmit, 
  onCancel,
  autoFocus = true
}) => {
  const [content, setContent] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autoFocus && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [autoFocus]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (content.trim()) {
      onSubmit(content);
      setContent('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="new-comment-form">
      <textarea
        ref={textareaRef}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="What are your thoughts?"
        className="comment-input"
      />
      <div className="comment-form-actions">
        {onCancel && (
          <button 
            type="button" 
            onClick={onCancel}
            className="cancel-button"
          >
            Cancel
          </button>
        )}
        <button 
          type="submit" 
          disabled={!content.trim()}
          className="submit-button"
        >
          Comment
        </button>
      </div>
    </form>
  );
};

export default NewCommentForm; 