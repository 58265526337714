import { create, StateCreator } from 'zustand'
import { persist, PersistOptions } from 'zustand/middleware'

// Add these type imports from zustand
import type { StoreApi } from 'zustand'

export interface Patient {
  id: string;
  name: string;
  breed: string;
  status?: "upcoming" | "inProgress" | "complete";
  appointmentTime?: string;
  appointmentType?: string;
  age?: string;
  sex?: string;
  weight?: string;
  pims_id?: string;
}

interface Appointment {
  patient_id: string;
  time: string;
  type: string;
  patient: string;
  duration: number;
  color?: string;
}

interface ProgressStats {
  completed: number;
  total: number;
  percentage: number;
}

interface PatientState {
  selectedPatient: Patient | null;
  selectedHistoricalNote: Patient | null;
  selectedPrimerPatient: Patient | null;
  patientsData: { [key: string]: Patient };
  progressStats: ProgressStats;
  
  // Actions
  setSelectedPatient: (patient: Patient | null) => void;
  setSelectedHistoricalNote: (patient: Patient | null) => void;
  setSelectedPrimerPatient: (patient: Patient | null) => void;
  setPatientsData: (data: { [key: string]: Patient }) => void;
  updatePatient: (patientId: string, updates: Partial<Patient>) => void;
  updateProgressStats: (appointments: Appointment[], patientsData: { [key: string]: Patient }) => void;
  moveToInProgress: (patientId: string, appointment: Appointment) => void;
  moveToComplete: (patientId: string) => void;
  clearSelections: () => void;
}

// Define the set and get types
type Set = StoreApi<PatientState>['setState']
type Get = StoreApi<PatientState>['getState']

// Define what we want to persist
interface PersistedState {
  selectedPatient: Patient | null;
  selectedPrimerPatient: Patient | null;
  patientsData: { [key: string]: Patient };
}

type PatientPersist = (
  config: StateCreator<PatientState>,
  options: PersistOptions<PatientState, PersistedState>
) => StateCreator<PatientState>;

export const usePatientStore = create<PatientState>()(
  (persist as PatientPersist)(
    (set: Set, get: Get) => ({
      selectedPatient: null,
      selectedHistoricalNote: null,
      selectedPrimerPatient: null,
      patientsData: {},
      progressStats: {
        completed: 0,
        total: 0,
        percentage: 0,
      },

      setSelectedPatient: (patient: Patient | null) => 
        set({ selectedPatient: patient }),
      
      setSelectedHistoricalNote: (patient: Patient | null) => 
        set({ selectedHistoricalNote: patient }),
      
      setSelectedPrimerPatient: (patient: Patient | null) => 
        set({ selectedPrimerPatient: patient }),
      
      setPatientsData: (data: { [key: string]: Patient }) => 
        set({ patientsData: data }),
      
      updatePatient: (patientId: string, updates: Partial<Patient>) => {
        set((state: PatientState) => ({
          patientsData: {
            ...state.patientsData,
            [patientId]: {
              ...state.patientsData[patientId],
              ...updates,
            },
          },
        }));
      },
      
      updateProgressStats: (appointments: Appointment[], patientsData: { [key: string]: Patient }) => {
        const validAppointments = appointments.filter(
          (app: Appointment) => 
            app.type !== "Block" && 
            app.type !== "Free Time" && 
            app.patient_id !== "0"
        );
        
        const completedCount = validAppointments.filter(
          (app: Appointment) => patientsData[app.patient_id]?.status === "complete"
        ).length;

        set({
          progressStats: {
            completed: completedCount,
            total: validAppointments.length,
            percentage: validAppointments.length
              ? (completedCount / validAppointments.length) * 100
              : 0,
          },
        });
      },
      
      moveToInProgress: (patientId: string, appointment: Appointment) => {
        set((state) => ({
          patientsData: {
            ...state.patientsData,
            [patientId]: {
              ...state.patientsData[patientId],
              status: "inProgress"
            }
          }
        }));
      },
      
      moveToComplete: (patientId: string) => {
        set((state) => ({
          patientsData: {
            ...state.patientsData,
            [patientId]: {
              ...state.patientsData[patientId],
              status: "complete"
            }
          }
        }));
      },
      
      clearSelections: () => {
        set({
          selectedPatient: null,
          selectedHistoricalNote: null,
          selectedPrimerPatient: null,
        });
      },
    }),
    {
      name: 'patient-storage',
      partialize: (state: PatientState): PersistedState => ({
        selectedPatient: state.selectedPatient,
        selectedPrimerPatient: state.selectedPrimerPatient,
        patientsData: state.patientsData,
      }),
    }
  )
) 