import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/PatientPrimer/Primer.css';
import AppointmentGuide from './AppointmentGuide';
import PrimerCards from './PrimerCards';
import { FaCheck } from 'react-icons/fa';
import {
  PencilSquareIcon,
  ChatBubbleBottomCenterTextIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import ReactMarkdown from 'react-markdown';
import PrimerPatientListItem from './PrimerPatientListItem';
import '../../styles/PatientPrimer/PrimerPatientListItem.css';
import { TaskItem } from '../TasksPanel';
import { collection, query, where, onSnapshot, updateDoc, deleteDoc, doc, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClinicPrimer from './ClinicPrimer';
import { usePatientStore } from '../../stores/patientStore';

//comment

const Primer = React.memo(({ selectedDoctor, refreshFirestoreData, refreshTrigger }) => {
  const { 
    selectedPrimerPatient, 
    setSelectedPrimerPatient,
    setSelectedPatient,
    moveToInProgress 
  } = usePatientStore();

  const getAppointmentMacroGroup = (type, appointmentTypes) => {
    if (!type || !appointmentTypes) return "Other";
    
    for (const [macroGroup, settings] of Object.entries(appointmentTypes)) {
      if (settings.subTypes?.includes(type)) {
        return macroGroup;
      }
    }
    return "Other";
  };

  const navigate = useNavigate();
  const [userData, setUserData] = useState(() => JSON.parse(localStorage.getItem('userData') || '{}'));
  const [hoveredAppointment, setHoveredAppointment] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [clickedCategory, setClickedCategory] = useState(null);
  const scrollContainerRef = useRef(null);
  const primerCardsRef = useRef(null);
  const hoverDelay = 100;
  const categoryHoverTimeout = useRef(null);
  const appointmentHoverTimeout = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [appointmentSchedule, setAppointmentSchedule] = useState([]);
  const [patientsData, setPatientsData] = useState({});

  const patientListRef = useRef(null);

  const [persistentSelection, setPersistentSelection] = useState(() => {
    const saved = sessionStorage.getItem('selectedPrimerPatient');
    return saved ? JSON.parse(saved) : null;
  });

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedFreeSlot, setSelectedFreeSlot] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState('');
  const [appointmentDuration, setAppointmentDuration] = useState(null);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(null);
  const [isUnassignedAppointment, setIsUnassignedAppointment] = useState(false);
  const [showUnassignedButtons, setShowUnassignedButtons] = useState(true);

  const [patientTasks, setPatientTasks] = useState({});
  const clinicId = userData.user?.clinicId;
  const userId = userData.user?.uid;

  const [showNewTaskForm, setShowNewTaskForm] = useState(false);
  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    dueDate: new Date(),
    priority: "medium"
  });

  const roundToNearest15Minutes = (date) => {
    const minutes = 15;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.round(date.getTime() / ms) * ms);
  };

  useEffect(() => {
    if (!clinicId || !userId || !selectedPrimerPatient?.id) {
      return;
    }

    const patientId = selectedPrimerPatient.id;

    const tasksRef = collection(db, "clinics", clinicId, "users", userId, "tasks");
    const q = query(tasksRef, where("patientId", "==", patientId));

    const unsubscribe = onSnapshot(q, (snapshot) => {

      const tasks = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        dueDate: doc.data().dueDate.toDate(),
        createdAt: doc.data().createdAt.toDate(),
        lastModified: doc.data().lastModified.toDate(),
      }));

      setPatientTasks(prev => {
        const updated = {
          ...prev,
          [selectedPrimerPatient.id]: tasks
        };
        return updated;
      });
    }, (error) => {
      console.error("Error fetching tasks:", error);
    });

    return () => {
      unsubscribe();
    };
  }, [clinicId, userId, selectedPrimerPatient?.id]);

  const handleToggleTask = async (taskId, currentStatus) => {
    if (!clinicId || !userId) return;

    const taskRef = doc(db, "clinics", clinicId, "users", userId, "tasks", taskId);
    const newStatus = currentStatus === "pending" ? "completed" : "pending";

    try {
      await updateDoc(taskRef, {
        status: newStatus,
        lastModified: new Date(),
      });
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  const handleDeleteTask = async (taskId) => {
    if (!clinicId || !userId) return;

    try {
      const taskRef = doc(db, "clinics", clinicId, "users", userId, "tasks", taskId);
      await deleteDoc(taskRef);
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const scrollToPatient = useCallback((patientId, time) => {
    if (!patientListRef.current) return;

    const patientElement = patientListRef.current.querySelector(
      `[data-patient-id="${patientId}"][data-time="${time}"]`
    );

    if (patientElement) {
      patientElement.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'nearest'
      });
    }
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    console.log('userData from localStorage:', userData);

    const doctorId = selectedDoctor?.value;

    // Reset clicked category when doctor changes
    setClickedCategory(null);

    if (doctorId && userData.user?.missionBrief?.[doctorId]) {
      const appointmentData = userData.user.missionBrief[doctorId].appointments || [];
      
      const processedAppointments = appointmentData
        .filter(apt => {
          return apt.patientData && apt.appointmentType !== 'Block';
        })
        .map(apt => ({
          patient_id: apt.patientData.patientId,
          time: apt.appointmentTime ? 
            new Date(apt.appointmentTime.seconds * 1000)
              .toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
              }).toLowerCase().replace(' ', '')
            : '12:00am',
          type: apt.appointmentType,
          duration: apt.duration,
          isUnassigned: !apt.appointmentTime,
          patientData: apt.patientData,
          appointmentColor: apt.appointmentColor,
          appointmentMacroGroup: apt.appointmentMacroGroup
        }));
            
      const patientsObj = processedAppointments.reduce((acc, apt) => {
        if (apt.patientData) {
          acc[apt.patient_id] = {
            id: apt.patient_id,
            name: apt.patientData.name,
            age: apt.patientData.age,
            sex: apt.patientData.sex,
            species: apt.patientData.species,
            breed: apt.patientData.breed,
            weight: apt.patientData.weight,
            birthday: apt.patientData.birthday,
            funFact: apt.patientData.funFact,
            alert: apt.patientData.alerts || [],
            primer: apt.patientData.missionBrief,
            reminders: apt.patientData.reminders || {},
            currentMedications: apt.patientData.medications || []
          };
        }
        return acc;
      }, {});
      
      setPatientsData(patientsObj);
      setAppointmentSchedule(processedAppointments);
    } else {
      console.log('Missing data check:', {
        hasDoctor: !!doctorId,
        hasMissionBrief: !!userData.user?.missionBrief,
        hasDoctorData: !!userData.user?.missionBrief?.[doctorId]
      });
    }
  }, [refreshTrigger, selectedDoctor]);

  const workdayStartHour = 8;
  const workdayEndHour = 20;
  const totalSlots = (workdayEndHour - workdayStartHour) * 4;

  // Helper functions first
  const formatTimeFromMinutes = (minutes) => {
    const hour = Math.floor(minutes / 60);
    const minute = minutes % 60;
    const period = hour >= 12 ? 'pm' : 'am';
    const displayHour = hour % 12 || 12;
    return `${displayHour}:${minute.toString().padStart(2, '0')}${period}`;
  };

  const parseTime = useCallback((timeStr) => {
    const timePattern = /^(\d{1,2}):(\d{2})(am|pm)$/i;
    const match = timeStr.match(timePattern);

    if (!match) {
      console.error('Invalid time format:', timeStr);
      return null;
    }

    let [_, hours, minutes, period] = match;
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (period.toLowerCase() === 'pm' && hours !== 12) {
      hours += 12;
    } else if (period.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  }, []);

  // Then the useMemo
  const combinedSchedule = useMemo(() => {
    // First, group appointments by their exact start time
    const appointmentsByTime = appointmentSchedule.reduce((groups, appointment) => {
      const time = appointment.time;
      if (!groups[time]) {
        groups[time] = [];
      }
      groups[time].push(appointment);
      return groups;
    }, {});

    // Convert the grouped appointments into the final format
    const finalSchedule = [];
    
    Object.entries(appointmentsByTime).forEach(([time, appointments]) => {
      if (appointments.length > 1) {
        finalSchedule.push({
          type: 'MultiAppointment',
          time: time,
          duration: appointments[0].duration,
          appointments: appointments.map(apt => ({
            ...apt,
            appointmentColor: apt.appointmentColor,
            appointmentMacroGroup: apt.appointmentMacroGroup,
            patientData: apt.patientData
          })),
          appointmentColor: appointments[0].appointmentColor,
          appointmentMacroGroup: appointments[0].appointmentMacroGroup
        });
      } else {
        finalSchedule.push(appointments[0]);
      }
    });

    // Sort by time
    finalSchedule.sort((a, b) => {
      const timeA = parseTime(a.time);
      const timeB = parseTime(b.time);
      return timeA - timeB;
    });

    // Add free time slots between appointments
    const completeSchedule = [];
    let currentTime = workdayStartHour * 60; // Start of day in minutes
    const endTime = workdayEndHour * 60; // End of day in minutes

    finalSchedule.forEach(appointment => {
      const appointmentTime = parseTime(appointment.time);
      
      // If there's a gap before this appointment, add a free time slot
      if (appointmentTime > currentTime) {
        completeSchedule.push({
          type: 'Free Time',
          time: formatTimeFromMinutes(currentTime),
          duration: appointmentTime - currentTime,
          startPosition: currentTime - (workdayStartHour * 60)
        });
      }

      // Add the appointment
      completeSchedule.push(appointment);
      currentTime = appointmentTime + (appointment.duration || 30);
    });

    // Add final free time slot if needed
    if (currentTime < endTime) {
      completeSchedule.push({
        type: 'Free Time',
        time: formatTimeFromMinutes(currentTime),
        duration: endTime - currentTime,
        startPosition: currentTime - (workdayStartHour * 60)
      });
    }

    return completeSchedule;
  }, [appointmentSchedule, workdayStartHour, workdayEndHour]);

  const appointmentTypesData = useMemo(() => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const appointmentTypes = userData.user?.settings?.missionBrief?.appointmentTypes || {};
    
    const typeCounts = {};
    
    combinedSchedule.forEach((slot) => {
      if (slot.type !== 'Free Time' && slot.type !== 'Block') {
        const typeData = getAppointmentMacroGroup(slot.type, appointmentTypes);
        const macroGroup = typeData.macroGroup;

        if (!typeCounts[macroGroup]) {
          typeCounts[macroGroup] = {
            type: macroGroup,
            count: 0,
            color: typeData.color,
            appointments: [],
          };
        }
        typeCounts[macroGroup].count += 1;
        typeCounts[macroGroup].appointments.push(slot);
      }
    });

    return Object.values(typeCounts).sort((a, b) => b.count - a.count);
  }, [combinedSchedule]);

  const filteredAppointmentSchedule = useMemo(() => {
    const appointmentsOnly = combinedSchedule.flatMap(slot => 
      slot.appointments && slot.appointments.length > 0
        ? slot.appointments.map(app => ({
            ...app,
            time: slot.time,
            isUnassigned: slot.isUnassigned,
            type: app.type || slot.type
          }))
        : [{...slot, type: slot.type}]
    ).filter(slot => slot.type !== 'Free Time' && slot.type !== 'Block');
  
    if (selectedPrimerPatient) {
      return appointmentsOnly.filter(
        (slot) =>
          slot.patient_id === selectedPrimerPatient.id &&
          (slot.time === selectedPrimerPatient.appointmentTime || slot.isUnassigned)
      );
    } else if (clickedCategory) {
      return appointmentsOnly.filter((slot) => 
        slot.appointmentMacroGroup === clickedCategory
      );
    } else {
      return appointmentsOnly;
    }
  }, [combinedSchedule, selectedPrimerPatient, clickedCategory]);

  const scrollToAppointment = useCallback((appointment) => {
    if (primerCardsRef.current) {
      primerCardsRef.current.scrollToAppointment(appointment);
    }
  }, []);
  
  const handleCategoryClick = useCallback(
    (category) => {
      // If clicking the same category again, just clear the category filter
      if (category === clickedCategory) {
        setClickedCategory(null);
        return;
      }

      // Set the clicked category first
      setClickedCategory(category);

      // If there's a current selection, check if it belongs to the new category
      if (selectedPrimerPatient && combinedSchedule.find(
        slot => 
          slot.appointmentMacroGroup === category &&
          slot.patient_id === selectedPrimerPatient.id
      )) {
        // Current selection belongs to this category, keep it
        return;
      }

      // If current selection isn't in this category, find the first appointment
      const firstAppointment = combinedSchedule.find(
        slot => 
          slot.appointmentMacroGroup === category && 
          slot.type !== 'Free Time' && 
          slot.type !== 'Block' &&
          !slot.isUnassigned
      );

      // If we found a matching appointment, select its patient
      if (firstAppointment) {
        setSelectedPrimerPatient({
          ...patientsData[firstAppointment.patient_id],
          appointmentTime: firstAppointment.time,
          appointmentType: firstAppointment.type,
          id: firstAppointment.patient_id,
          status: firstAppointment.patientData?.status || firstAppointment.status || 'upcoming'
        });
      }
    },
    [clickedCategory, combinedSchedule, patientsData, selectedPrimerPatient]
  );

  const handleBarChartMouseEnter = useCallback((category) => {
    categoryHoverTimeout.current = setTimeout(() => {
      setHoveredCategory(category);
    }, hoverDelay);
  }, []);

  const handleBarChartMouseLeave = useCallback(() => {
    clearTimeout(categoryHoverTimeout.current);
    setHoveredCategory(null);
  }, []);

  const handleAppointmentHover = useCallback((appointment) => {
    if (appointment.type !== 'Free Time') {
      appointmentHoverTimeout.current = setTimeout(() => {
        setHoveredAppointment(appointment);
        scrollToAppointment(appointment);
      }, hoverDelay);
    }
  }, [scrollToAppointment]);

  const handleAppointmentLeave = useCallback(() => {
    clearTimeout(appointmentHoverTimeout.current);
    setHoveredAppointment(null);
  }, []);

  const handleAppointmentClick = (appointment) => {
    if (selectedPrimerPatient && 
        selectedPrimerPatient.id === appointment.patient_id && 
        selectedPrimerPatient.appointmentTime === appointment.time) {
      setSelectedPrimerPatient(null);
    } else {
      setSelectedPrimerPatient({
        ...patientsData[appointment.patient_id],
        appointmentTime: appointment.time,
        appointmentType: appointment.type,
        id: appointment.patient_id,
        status: appointment.patientData?.status || appointment.status || 'upcoming'
      });
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      setScrollPosition(scrollContainerRef.current.scrollTop);
    }
  }, []);

  const handleCategoryHover = useCallback((category) => {
    setHoveredCategory(category);
  }, []);

  const handleCategoryLeave = useCallback(() => {
    setHoveredCategory(null);
  }, []);

  const unassignedAppointments = useMemo(() => {
    return combinedSchedule.filter(slot => slot.isUnassigned);
  }, [combinedSchedule]);

  const allAppointments = useMemo(() => {
    const appointments = combinedSchedule
      .filter(slot => slot && slot.patient_id !== '0' && slot.patient !== 'Block')
      .flatMap(slot => {
        if (slot.appointments && slot.appointments.length > 0) {
          return slot.appointments.map(app => ({
            ...app,
            time: slot.time,
            isUnassigned: slot.isUnassigned,
            type: app.type || slot.type
          }));
        } else {
          return [{
            ...slot,
            type: slot.type
          }];
        }
      })
      .filter(slot => slot.type !== 'Free Time' && slot.type !== 'Block');

    if (clickedCategory) {
      return appointments.filter(appointment => 
        appointment.appointmentMacroGroup === clickedCategory
      );
    }

    return appointments;
  }, [combinedSchedule, clickedCategory]);

  useEffect(() => {
    if (persistentSelection) {
      setSelectedPrimerPatient(persistentSelection);
      scrollToPatient(persistentSelection.patient_id, persistentSelection.time);
    }
  }, []);

  const formatTimeToAMPM = (timeStr) => {
    // Handle special case for "12:00am"
    if (timeStr.toLowerCase().endsWith('am') || timeStr.toLowerCase().endsWith('pm')) {
      return timeStr.toLowerCase();
    }
    
    const [hours, minutes] = timeStr.split(':').map(Number);
    const period = hours >= 12 ? 'pm' : 'am';
    const displayHours = hours % 12 || 12;
    return `${displayHours}:${minutes.toString().padStart(2, '0')}${period}`;
  };

  const handleStartSOAP = (patient) => {
    const patientForStore = {
      id: patient.id,
      name: patient.name,
      breed: patient.breed,
      age: patient.age,
      sex: patient.sex,
      weight: patient.weight,
      appointmentTime: patient.appointmentTime,
      appointmentType: patient.appointmentType,
      hasNote: patient.hasNote,
      inProgress: false
    };

    setSelectedPatient(patientForStore);
    
    moveToInProgress(patient.id, {
      patient_id: patient.id,
      time: patient.appointmentTime,
      type: patient.appointmentType,
      patient: patient.name,
      duration: 30
    });

    navigate('/scribe');
  };

  const handleNavigation = (path, patient) => {
    if (path === '/Chat') {
      // Find the chat menu item and trigger its click handler
      const chatMenuItem = document.querySelector('[data-menu-item="Chat"]');
      if (chatMenuItem) {
        const event = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        });
        Object.defineProperty(event, 'patient', { value: patient });
        chatMenuItem.dispatchEvent(event);
      }
    } else {
      navigate(path);
    }
  };

  const handleStartChat = (patient) => {
    handleNavigation('/Chat', patient);
  };

  const ReminderTable = ({ patient }) => {
    const reminders = patient.reminders || {};
    
    const formatReminder = (reminder, type) => {
      return {
        ...reminder,
        type,
        diff: reminder.dateDifference,
        due_date: reminder.dueDate
      };
    };

    const sortedReminders = useMemo(() => {
      const overdue = (reminders.Overdue || []).map(r => formatReminder(r, 'Overdue'));
      const upcoming = (reminders.Upcoming || []).map(r => formatReminder(r, 'Upcoming'));
      const future = (reminders.Future || []).map(r => formatReminder(r, 'Future'));

      return [...overdue, ...upcoming, ...future];
    }, [reminders]);

    const getRowStyle = (type) => {
      switch (type) {
        case 'Upcoming':
          return { backgroundColor: 'rgba(255, 249, 196, 0.5)' };
        case 'Future':
          return { backgroundColor: 'rgba(200, 230, 201, 0.5)' };
        case 'Overdue':
          return { backgroundColor: 'rgba(255, 205, 210, 0.5)' };
        default:
          return {};
      }
    };

    return (
      <div className="reminder-table-container">
        <div className="reminder-table-wrapper">
          {sortedReminders.length === 0 ? (
            <div className="no-reminders-placeholder">
              No reminders available
            </div>
          ) : (
            <table className="reminder-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Due Date</th>
                  <th>Description</th>
                  <th>Days Until/Since Due</th>
                </tr>
              </thead>
              <tbody>
                {sortedReminders.map((reminder, index) => (
                  <tr key={index} style={getRowStyle(reminder.type)}>
                    <td>{reminder.type}</td>
                    <td>{reminder.due_date}</td>
                    <td>{reminder.description}</td>
                    <td>
                      {Math.abs(reminder.diff)}{' '}
                      {reminder.diff >= 0
                        ? 'days until due'
                        : 'days overdue'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  };

  const formatBirthday = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const getOrdinalSuffix = (n) => {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };

    return `${months[date.getMonth()]} ${getOrdinalSuffix(date.getDate())}, ${date.getFullYear()}`;
  };

  const isBirthdaySoon = (birthday) => {
    const today = new Date();
    const birthDate = new Date(birthday);
    const thisYearsBirthday = new Date(
      today.getFullYear(),
      birthDate.getMonth(),
      birthDate.getDate()
    );
    const timeDiff = thisYearsBirthday.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff >= 0 && daysDiff <= 30;
  };

  const countReminders = (patient) => {
    if (!patient) return 0;
    const reminders = patient.reminders || {};
    return (
      (reminders.Upcoming?.length || 0) +
      (reminders.Future?.length || 0) +
      (reminders.Overdue?.length || 0)
    );
  };

  const findFirstAvailableSlot = useCallback(() => {
    // Find all Free Time and Block slots that are at least 30 minutes long
    const availableSlots = combinedSchedule.filter(slot => 
      (slot.type === 'Free Time' || slot.type === 'Block') && 
      slot.duration >= 2 // 2 slots = 30 minutes
    );

    if (availableSlots.length > 0) {
      // Return the first available slot but include all available slots
      return {
        selectedSlot: availableSlots[0],
        allAvailableSlots: availableSlots,
        time: availableSlots[0].time,
        duration: availableSlots[0].duration
      };
    }

    return null;
  }, [combinedSchedule]);

  const handleAddPatient = () => {
    const availableSlotData = findFirstAvailableSlot();
    
    // Create an empty appointment object
    const emptyAppointment = {
      patient_id: '',
      time: availableSlotData ? availableSlotData.time : '12:00am',
      type: '',
      isUnassigned: true,
      duration: availableSlotData ? availableSlotData.duration : 2,
      availableSlots: availableSlotData ? availableSlotData.allAvailableSlots : []
    };
    

    setSelectedSlot(emptyAppointment);
    setSelectedFreeSlot(emptyAppointment);
    setSelectedStartTime('');
    setAppointmentDuration(null);
    setSelectedPatient('');
    setSelectedAppointmentType(null);
    setIsUnassignedAppointment(false);
    setShowUnassignedButtons(true);
  };

  const MedicationTable = ({ patient }) => {
    const medications = patient.currentMedications || [];

    return (
      <div className="medication-table-container">
        <div className="medication-table-wrapper">
          {medications.length === 0 ? (
            <div className="no-medications-placeholder">
              No medications available
            </div>
          ) : (
            <table className="medication-table">
              <thead>
                <tr>
                  <th>Medication</th>
                  <th>Dosage</th>
                  <th>Frequency</th>
                  <th>Start Date</th>
                </tr>
              </thead>
              <tbody>
                {medications.map((med, index) => (
                  <tr key={index}>
                    <td>{med.name || med.medication}</td>
                    <td>{med.dose}</td>
                    <td>{med.frequency}</td>
                    <td>{med.startDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  };

  const handleAddTask = async () => {
    if (!clinicId || !userId || !selectedPrimerPatient?.id || !newTask.title) return;

    try {
      const taskData = {
        title: newTask.title,
        description: newTask.description,
        status: "pending",
        priority: newTask.priority,
        dueDate: Timestamp.fromDate(newTask.dueDate),
        createdAt: Timestamp.fromDate(new Date()),
        lastModified: Timestamp.fromDate(new Date()),
        patientId: selectedPrimerPatient.id,
        patientName: selectedPrimerPatient.name
      };

      await addDoc(collection(db, "clinics", clinicId, "users", userId, "tasks"), taskData);

      // Force a refresh of the patient list items
      const patientListItems = document.querySelectorAll('.primer-patient-item');
      patientListItems.forEach(item => {
        const patientId = item.getAttribute('data-patient-id');
        if (patientId === selectedPrimerPatient.id) {
          // Trigger a re-render by dispatching a custom event
          const updateEvent = new CustomEvent('taskAdded', { 
            detail: { patientId: selectedPrimerPatient.id } 
          });
          window.dispatchEvent(updateEvent);
        }
      });

      // Reset form
      setNewTask({
        title: "",
        description: "",
        dueDate: new Date(),
        priority: "medium"
      });
      setShowNewTaskForm(false);
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  return (
    <div className="primer-container">
      <AppointmentGuide
        appointmentTypesData={appointmentTypesData}
        combinedSchedule={combinedSchedule.filter(slot => !slot.isUnassigned)}
        totalSlots={totalSlots}
        workdayStartHour={workdayStartHour}
        workdayEndHour={workdayEndHour}
        hoveredCategory={hoveredCategory}
        clickedCategory={clickedCategory}
        hoveredAppointment={hoveredAppointment}
        selectedAppointment={selectedPrimerPatient}
        handleBarChartMouseEnter={handleCategoryHover}
        handleBarChartMouseLeave={handleCategoryLeave}
        handleCategoryClick={handleCategoryClick}
        handleAppointmentHover={handleAppointmentHover}
        handleAppointmentLeave={handleAppointmentLeave}
        handleAppointmentClick={handleAppointmentClick}
        patientMockData={patientsData}
        handleCategoryHover={handleCategoryHover}
        unassignedAppointments={unassignedAppointments}
        selectedDoctor={selectedDoctor}
        refreshFirestoreData={refreshFirestoreData}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        selectedFreeSlot={selectedFreeSlot}
        setSelectedFreeSlot={setSelectedFreeSlot}
        selectedStartTime={selectedStartTime}
        setSelectedStartTime={setSelectedStartTime}
        appointmentDuration={appointmentDuration}
        setAppointmentDuration={setAppointmentDuration}
        selectedAppointmentType={selectedAppointmentType}
        setSelectedAppointmentType={setSelectedAppointmentType}
        isUnassignedAppointment={isUnassignedAppointment}
        setIsUnassignedAppointment={setIsUnassignedAppointment}
        showUnassignedButtons={showUnassignedButtons}
        setShowUnassignedButtons={setShowUnassignedButtons}
      />
      <div className="blank-container" ref={scrollContainerRef}>
        <div className="patient-list-container">
          <div className="patient-list-header">
            <div className="patient-count">
              Total Patients: {allAppointments.length}
            </div>
            <button 
              className="add-patient-button"
              onClick={handleAddPatient}
            >
              + Add Patient
            </button>
          </div>
          <div className={`patient-list ${selectedPrimerPatient ? 'has-selection' : ''}`} ref={patientListRef}>
            {/* Assigned Appointments */}
            <div className="appointment-group">
              <div className="appointment-group-header">
                Scheduled Appointments: {allAppointments.filter(appointment => !appointment.isUnassigned).length}
              </div>
              {allAppointments
                .filter(appointment => !appointment.isUnassigned)
                .map((appointment, index) => (
                  <PrimerPatientListItem
                    key={`assigned-${index}`}
                    appointment={appointment}
                    patient={patientsData[appointment.patient_id]}
                    isSelected={selectedPrimerPatient && 
                      selectedPrimerPatient.id === appointment.patient_id &&
                      selectedPrimerPatient.time === appointment.time}
                    onClick={() => handleAppointmentClick(appointment)}
                    clickedCategory={clickedCategory}
                    appointmentTypes={userData.user?.settings?.appointmentTypes}
                  />
                ))}
            </div>

            {/* Unassigned Appointments */}
            {allAppointments.some(appointment => appointment.isUnassigned) && (
              <div className="appointment-group">
                <div className="appointment-group-header">
                  Unscheduled Appointments: {allAppointments.filter(appointment => appointment.isUnassigned).length}
                </div>
                {allAppointments
                  .filter(appointment => appointment.isUnassigned)
                  .map((appointment, index) => (
                    <PrimerPatientListItem
                      key={`unassigned-${index}`}
                      appointment={appointment}
                      patient={patientsData[appointment.patient_id]}
                      isSelected={selectedPrimerPatient && 
                        selectedPrimerPatient.id === appointment.patient_id &&
                        selectedPrimerPatient.time === appointment.time}
                      onClick={() => handleAppointmentClick(appointment)}
                      clickedCategory={clickedCategory}
                      appointmentTypes={userData.user?.settings?.appointmentTypes}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className="patient-details">
          {selectedPrimerPatient && patientsData[selectedPrimerPatient.id] ? (
            <>
              <div className="header">
                <div className="header-content">
                  <div className="header-top">
                    <div className="header-info">
                      <div className="header-title">
                        {patientsData[selectedPrimerPatient.id]?.name} @ {formatTimeToAMPM(selectedPrimerPatient.appointmentTime)} | {selectedPrimerPatient.appointmentType}
                      </div>
                      <div className="patient-metadata">
                        {patientsData[selectedPrimerPatient.id] && (
                          <>
                            <span className="metadata-item">
                              <span 
                                className={`birthday-icon ${isBirthdaySoon(patientsData[selectedPrimerPatient.id].birthday) ? 'birthday-soon' : ''}`}
                                onMouseEnter={(e) => {
                                  const tooltip = document.createElement('div');
                                  tooltip.className = 'birthday-tooltip';
                                  tooltip.textContent = ` \u00A0(${formatBirthday(patientsData[selectedPrimerPatient.id].birthday)})`;
                                  e.currentTarget.appendChild(tooltip);
                                }}
                                onMouseLeave={(e) => {
                                  const tooltip = e.currentTarget.querySelector('.birthday-tooltip');
                                  if (tooltip) tooltip.remove();
                                }}
                              >
                                {'\uD83C\uDF82'} {patientsData[selectedPrimerPatient.id].age}
                              </span>
                            </span>
                            <span className="metadata-item">
                              {patientsData[selectedPrimerPatient.id]?.sex === 'MN' ? '\u2642\uFE0F' : '\u2640\uFE0F'}{' '}
                              {patientsData[selectedPrimerPatient.id]?.sex}
                            </span>
                            <span className="metadata-item">
                              {patientsData[selectedPrimerPatient.id]?.species === 'Feline' ? '\uD83D\uDC31' : '\uD83D\uDC36'}{' '}
                              {patientsData[selectedPrimerPatient.id]?.breed}
                            </span>
                            <span className="metadata-item">
                              {'\u2696\uFE0F'} {patientsData[selectedPrimerPatient.id]?.weight}
                            </span>
                            <span className="metadata-item">
                              <span className="reminder-count">
                                📅 {countReminders(patientsData[selectedPrimerPatient.id])} reminders
                              </span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="header-buttons">
                      <button
                        className="header-button soap-button flex items-center justify-center space-x-2 px-4 py-2 rounded-md 
                                   text-deep-space hover:bg-cosmic-cyan-20 
                                   transition-colors duration-150 w-28"
                        style={{
                          backgroundColor: selectedPrimerPatient?.status === 'complete' ? '#f0fdf4' : ''
                        }}
                        onClick={() => handleStartSOAP(selectedPrimerPatient)}
                      >
                        <PencilSquareIcon className="w-5 h-5" />
                        <span>SOAP</span>
                        {selectedPrimerPatient?.status === 'complete' && 
                          <FaCheck 
                            style={{
                              color: '#4caf50',
                              fontSize: '18px',
                              position: 'absolute',
                              top: '-8px',
                              right: '-8px',
                              backgroundColor: 'white',
                              borderRadius: '50%',
                              padding: '3px'
                            }}
                          />
                        }
                      </button>
                      <button
                        className="header-button flex items-center justify-center space-x-2 px-4 py-2 rounded-md 
                                   text-deep-space hover:bg-cosmic-cyan-20 
                                   transition-colors duration-150 w-28"
                        onClick={() => handleStartChat(selectedPrimerPatient)}
                      >
                        <ChatBubbleBottomCenterTextIcon className="w-5 h-5" />
                        <span>Chat</span>
                      </button>
                    </div>
                  </div>
                  
                  {patientsData[selectedPrimerPatient.id]?.alert?.length > 0 && (
                    <div className="alerts-wrapper">
                      <div className="alert-section">
                        <ExclamationTriangleIcon className="alert-icon" />
                        <div className="alert-text">
                          {patientsData[selectedPrimerPatient.id].alert.map((alert, index, array) => (
                            <span key={index}>
                              • {alert}
                              {index < array.length - 1 && <br />}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="details-container">
                <div className="details-main">
                  <div className="text-lg font-semibold text-[#0D1140] mb-4">Primer</div>
                  <div className="primer-text">
                    <ReactMarkdown>
                      {patientsData[selectedPrimerPatient.id]?.primer || 'No primer information available.'}
                    </ReactMarkdown>
                  </div>
                  
                  {patientsData[selectedPrimerPatient.id]?.funFact && (
                    <div className="fun-fact-section">
                      <span className="fun-fact-icon">💡</span>
                      <div className="fun-fact-text">
                        {patientsData[selectedPrimerPatient.id].funFact}
                      </div>
                    </div>
                  )}

                  <div className="tables-section">
                    <div className="reminders-section">
                      <div className="section-title">Reminders</div>
                      <ReminderTable patient={patientsData[selectedPrimerPatient.id]} />
                    </div>
                    <div className="medications-section">
                      <div className="section-title">Medications</div>
                      <MedicationTable patient={patientsData[selectedPrimerPatient.id]} />
                    </div>
                  </div>
                </div>

                <div className="details-tasks">
                  <div className="tasks-header">
                    <h3 className="text-lg font-semibold text-[#0D1140] mb-4">Tasks</h3>
                  </div>

                  {selectedPrimerPatient && (
                    <button
                      onClick={() => setShowNewTaskForm(!showNewTaskForm)}
                      className="w-full mb-6 flex items-center justify-center gap-2 px-4 py-3
                                 bg-cosmic-cyan/10 text-cosmic-cyan rounded-xl
                                 transition-all duration-300
                                 hover:bg-cosmic-cyan/20
                                 shadow-sm"
                    >
                      <PlusIcon className="w-5 h-5" />
                      <span>Add new task</span>
                    </button>
                  )}

                  {/* New Task Form */}
                  {showNewTaskForm && selectedPrimerPatient && (
                    <div className="px-6 py-4 bg-gray-50/80 border border-gray-100 rounded-xl mb-4">
                      <div className="space-y-4">
                        {/* Title Input */}
                        <input
                          type="text"
                          value={newTask.title}
                          onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                          placeholder="Task title *"
                          className="w-full p-3 bg-white rounded-xl
                                    text-gray-700 placeholder-gray-400
                                    border border-gray-200
                                    focus:outline-none focus:ring-1 focus:ring-cosmic-cyan"
                        />

                        {/* Description */}
                        <textarea
                          value={newTask.description}
                          onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                          placeholder="Add description (optional)"
                          className="w-full p-3 bg-white rounded-xl
                                    text-gray-700 placeholder-gray-400
                                    border border-gray-200
                                    focus:outline-none focus:ring-1 focus:ring-cosmic-cyan
                                    resize-none h-24"
                        />

                        {/* Due Date and Priority Row */}
                        <div className="flex gap-3">
                          <div className="flex-1">
                            <DatePicker
                              selected={newTask.dueDate}
                              onChange={(date) => setNewTask({
                                ...newTask,
                                dueDate: roundToNearest15Minutes(date || new Date())
                              })}
                              showTimeSelect
                              timeIntervals={15}
                              minTime={new Date(new Date().setHours(5, 0, 0, 0))}
                              maxTime={new Date(new Date().setHours(22, 0, 0, 0))}
                              dateFormat="MMM d, yyyy h:mm aa"
                              className="w-full p-3 bg-white rounded-xl
                                        text-gray-700 border border-gray-200
                                        focus:outline-none focus:ring-1 focus:ring-cosmic-cyan"
                              placeholderText="Select date and time"
                              popperClassName="react-datepicker-popper"
                              calendarClassName="rounded-lg border border-gray-200 shadow-lg"
                              wrapperClassName="w-full"
                            />
                          </div>

                          <select
                            value={newTask.priority}
                            onChange={(e) => setNewTask({
                              ...newTask,
                              priority: e.target.value
                            })}
                            className="flex-1 p-3 bg-white rounded-xl
                                      text-gray-700 border border-gray-200
                                      focus:outline-none focus:ring-1 focus:ring-cosmic-cyan
                                      cursor-pointer appearance-none"
                          >
                            <option value="low">Low Priority</option>
                            <option value="medium">Medium Priority</option>
                            <option value="high">High Priority</option>
                          </select>
                        </div>

                        {/* Action Buttons */}
                        <div className="flex gap-3 pt-2">
                          <button
                            onClick={() => setShowNewTaskForm(false)}
                            className="flex-1 py-2 px-4 rounded-xl
                                      border border-gray-200 text-gray-600
                                      hover:bg-gray-50 transition-colors"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleAddTask}
                            className="flex-1 py-2 px-4 rounded-xl
                                      bg-cosmic-cyan text-white
                                      hover:bg-cosmic-cyan/90 transition-colors
                                      disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={!newTask.title}
                          >
                            Add Task
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="tasks-content">
                    {selectedPrimerPatient && patientTasks[selectedPrimerPatient.id]?.length > 0 ? (
                      <div className="grid grid-cols-1 gap-4">
                        {patientTasks[selectedPrimerPatient.id].map((task) => (
                          <TaskItem
                            key={task.id}
                            task={task}
                            onToggle={handleToggleTask}
                            onDelete={handleDeleteTask}
                          />
                        ))}
                      </div>
                    ) : (
                      <p className="text-gray-500 text-sm italic">No tasks for this patient</p>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ClinicPrimer />
          )}
        </div>
      </div>
    </div>
  );
});

export default Primer;