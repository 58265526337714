import React, { useState, useEffect, useRef, useCallback } from "react";
import { FaPencilAlt, FaMicrophone } from "react-icons/fa";
import { storage, db, auth } from "../config/firebase";
import MedicalScribe from "../components/MedicalScribe";
import "../styles/QuickSOAP.css";
import HistoricalNote from "../components/HistoricalNote";
import { audioStorage } from "../services/AudioStorageService";

interface QuickSOAPProps {
  refreshFirestoreData: () => Promise<void>;
  refreshTrigger: number;
  selectedPatient: Patient | null;
  selectedHistoricalNote: Patient | null;
  onCloseHistoricalNote: () => void;
  appointmentTypes: AppointmentTypes;
  onStartNote?: () => void;
}

interface Patient {
  id: string;
  name: string;
  breed: string;
  age?: string;
  sex?: string;
  weight?: string;
  hasNote?: boolean;
  inProgress?: boolean;
  appointmentTime?: string;
  appointmentType?: string;
  color?: string;
  pims_id?: string;
}

interface StaticInfo {
  formattedDate: string;
  time: string;
  patientName: string;
  patientBreed: string;
}

interface AppointmentTypeSettings {
  types: string[];
  color: string;
}

interface AppointmentTypes {
  [key: string]: AppointmentTypeSettings;
}

interface RecordingInfo {
  startTime: number;
  duration: number;
}

const QuickSOAP: React.FC<QuickSOAPProps> = ({
  refreshFirestoreData,
  refreshTrigger,
  selectedPatient,
  selectedHistoricalNote,
  onCloseHistoricalNote,
  appointmentTypes,
  onStartNote,
}) => {
  const [soapMode, setSoapMode] = useState<"spoken" | "written">("spoken");
  const [showToggle] = useState<boolean>(true);
  const [currentStaticInfo, setCurrentStaticInfo] = useState<StaticInfo | null>(
    null
  );
  const [currentDate] = useState<Date>(new Date());
  const [recordingGroups, setRecordingGroups] = useState<RecordingInfo[]>([]);

  const medicalScribeRef = useRef<any>(null);

  useEffect(() => {
    const container = document.querySelector(
      ".quicksoap-container"
    ) as HTMLElement;
    if (container) {
      container.style.setProperty("--star-delay", String(Math.random() * 100));
    }
  }, []);

  const handleModeChange = (mode: "spoken" | "written") => {
    if (mode !== soapMode) {
      if (medicalScribeRef.current) {
        medicalScribeRef.current.handleDiscard();
      }
      setSoapMode(mode);
    }
  };

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);

    const day = date.getDate();
    let suffix = "th";
    if (day % 10 === 1 && day !== 11) {
      suffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      suffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      suffix = "rd";
    }

    return formattedDate.replace(/(\d+)(?=(,\s\d{4}))/, `$1${suffix}`);
  };

  const getCurrentDateTime = useCallback((): StaticInfo => {
    const now = new Date(currentDate);
    return {
      formattedDate: formatDate(now),
      time: now.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      }),
      patientName: selectedPatient ? selectedPatient.name : "Unknown",
      patientBreed: selectedPatient ? selectedPatient.breed : "Unknown",
    };
  }, [currentDate, selectedPatient]);

  useEffect(() => {
    setCurrentStaticInfo(getCurrentDateTime());
  }, [selectedPatient, currentDate, getCurrentDateTime]);

  useEffect(() => {
    if (refreshTrigger) {
      const userData = JSON.parse(localStorage.getItem("userData") || "{}");
      const savedPatient = localStorage.getItem("selectedPatient");
      const patients = userData.user?.dailyPrimer?.patients?.patients || {};
      const patientsArray = Array.isArray(patients)
        ? patients
        : Object.values(patients);

      if (savedPatient) {
        try {
          const parsedPatient = JSON.parse(savedPatient);
          const patient = patientsArray.find((p) => p.id === parsedPatient.id);
          if (patient) {
            setCurrentStaticInfo((prevInfo) => ({
              ...prevInfo!,
              patientName: patient.name,
              patientBreed: patient.breed,
              formattedDate: prevInfo?.formattedDate || "",
              time: prevInfo?.time || "",
            }));
          }
        } catch (error) {
          console.error("Error parsing savedPatient from localStorage", error);
        }
      }
    }
  }, [refreshTrigger]);

  const getPastelColor = (hexColor: string) => {
    let r = parseInt(hexColor.slice(1, 3), 16);
    let g = parseInt(hexColor.slice(3, 5), 16);
    let b = parseInt(hexColor.slice(5, 7), 16);

    r = Math.floor((r + 255) / 2);
    g = Math.floor((g + 255) / 2);
    b = Math.floor((b + 255) / 2);

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  const getAppointmentMacroGroup = (
    subType?: string,
    types?: AppointmentTypes
  ) => {
    if (!subType || !types) {
      return {
        macroGroup: "Other",
        color: getPastelColor("#A5B5BD"),
      };
    }

    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const appointmentTypes = userData.user?.settings?.appointmentTypes || {};

    console.log('subType:', subType);
    console.log('appointmentTypes:', appointmentTypes);

    if (appointmentTypes[subType]) {
      return {
        macroGroup: subType,
        color: getPastelColor(appointmentTypes[subType].color)
      };
    }

    const entries = Object.entries(types) as [
      string,
      AppointmentTypeSettings
    ][];
    for (const [macroGroup, settings] of entries) {
      if (settings.types && settings.types.includes(subType)) {
        return {
          macroGroup,
          color: getPastelColor(settings.color),
        };
      }
    }

    return {
      macroGroup: "Other",
      color: getPastelColor(types.Other?.color || "#A5B5BD"),
    };
  };

  useEffect(() => {
    const checkForRecordings = async () => {
      if (selectedPatient?.id) {
        try {
          const groups = await audioStorage.getRecordingGroups(
            selectedPatient.id
          );
          setRecordingGroups(groups);
        } catch (error) {
          console.error("Error checking recordings:", error);
          setRecordingGroups([]);
        }
      } else {
        setRecordingGroups([]);
      }
    };

    checkForRecordings();
  }, [selectedPatient]);

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const formatTimeAgo = (timestamp: number): string => {
    const minutes = Math.floor((Date.now() - timestamp) / (60 * 1000));
    if (minutes < 60) return `${minutes}m ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours}h ago`;
    return `${Math.floor(hours / 24)}d ago`;
  };

  return (
    <div className={`quicksoap-container ${showToggle ? "show-toggle" : ""}`}>
      <div className="stars-layer"></div>
      {selectedPatient ? (
        <div className={`top-controls ${showToggle ? "show-toggle" : ""}`}>
          {currentStaticInfo && (
            <>
              <div className="patient-container">
                <div className="header-row">
                  <div className="patient-info">
                    <span className="patient-name">
                      {currentStaticInfo.patientName}
                    </span>
                    <span className="pims-id">
                      ({selectedPatient?.pims_id || "N/A"})
                    </span>
                    <span className="time">@ {currentStaticInfo.time}</span>
                    <span className="separator">|</span>
                    <span
                      className="appointment-type-tag"
                      style={{
                        backgroundColor: selectedPatient?.color ? getPastelColor(selectedPatient.color) : getPastelColor('#A5B5BD'),
                      }}
                    >
                      {selectedPatient?.appointmentType || "General"}
                    </span>
                  </div>
                  <div className="toggle-container">
                    <div className="custom-toggle">
                      <div
                        className={`toggle-option ${
                          soapMode === "written" ? "active" : ""
                        }`}
                        onClick={() => handleModeChange("written")}
                      >
                        <FaPencilAlt className="toggle-icon" />
                      </div>
                      <div
                        className={`toggle-option ${
                          soapMode === "spoken" ? "active" : ""
                        }`}
                        onClick={() => handleModeChange("spoken")}
                      >
                        <FaMicrophone className="toggle-icon" />
                      </div>
                      <div
                        className="toggle-slider"
                        style={{
                          transform: `translateX(${
                            soapMode === "spoken" ? "100%" : "0"
                          })`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="metadata-row">
                  <div className="metadata-item">
                    <span className="label">🎂</span>
                    <span className="value">{selectedPatient?.age || "N/A"}</span>
                  </div>
                  <div className="separator">|</div>
                  <div className="metadata-item">
                    <span className="label">⚤</span>
                    <span className="value">{selectedPatient?.sex || "N/A"}</span>
                  </div>
                  <div className="separator">|</div>
                  <div className="metadata-item">
                    <span className="label">🐾 </span>
                    <span className="value">
                      {selectedPatient?.breed || "N/A"}
                    </span>
                  </div>
                  <div className="separator">|</div>
                  <div className="metadata-item">
                    <span className="label">⚖️ </span>
                    <span className="value">
                      {selectedPatient?.weight || "N/A"}
                    </span>
                  </div>
                </div>
              </div>

              {!selectedHistoricalNote && recordingGroups.length > 0 && (
                <div className="artifacts-row">
                  <span className="artifacts-label">Artifacts:</span>
                  {recordingGroups.map((group, index) => (
                    <span key={index} className="recording-tag">
                      Recording | {formatDuration(group.duration)} (
                      {formatTimeAgo(group.startTime)})
                    </span>
                  ))}
                </div>
              )}

              {selectedHistoricalNote ? (
                <HistoricalNote
                  patient={selectedHistoricalNote}
                  onClose={onCloseHistoricalNote}
                />
              ) : (
                <MedicalScribe
                  ref={medicalScribeRef}
                  soapMode={soapMode}
                  auth={auth}
                  storage={storage}
                  db={db}
                  selectedPatient={selectedPatient}
                  refreshFirestoreData={refreshFirestoreData}
                  onStartNote={onStartNote}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <div className="empty-state">
          <div className="empty-state-content">
            <h2>Please Select a Patient</h2>
            <p>Select a patient from the list to start their SOAP note</p>
            <p className="cheeky-text">(or take a break, we'll start when you're ready ✨)</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuickSOAP;
