import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import '../../styles/SettingsStyles/PrimerSettings.css';
import { db } from '../../config/firebase';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { useUser } from '../../App';

function PrimerSettings() {
  const { user } = useUser();
  const [userData, setUserData] = useState(() => 
    JSON.parse(localStorage.getItem('userData') || '{}')
  );
  const clinicId = userData.user?.clinic?.clinicId || userData.user?.clinicId;

  const appointmentTypes = userData.user?.settings?.appointmentTypes || {};
  
  useEffect(() => {
    document.body.style.userSelect = 'none';
    return () => {
      document.body.style.userSelect = 'auto';
    };
  }, []);

  const saveToFirestore = async (typeKey, updatedData) => {
    if (!user || !clinicId) {
      console.error('❌ Missing required data:', { user, clinicId });
      return;
    }

    try {
      const macroGroupRef = doc(
        db, 
        'clinics', 
        clinicId, 
        'users', 
        user.uid,
        'settings',
        'missionBrief',
        'appointmentTypes',
        typeKey
      );

      await updateDoc(macroGroupRef, {
        subTypes: updatedData.subTypes,
        color: updatedData.color
      });

    } catch (error) {
      console.error('❌ Error updating appointment types:', error);
      throw error;
    }
  };

  const handleColorChange = (typeKey, newColor) => {
    const updatedTypeData = {
      ...appointmentTypes[typeKey],
      color: newColor
    };

    const updatedUserData = {
      ...userData,
      user: {
        ...userData.user,
        settings: {
          ...userData.user.settings,
          appointmentTypes: {
            ...appointmentTypes,
            [typeKey]: updatedTypeData
          }
        }
      }
    };

    setUserData(updatedUserData);
    localStorage.setItem('userData', JSON.stringify(updatedUserData));
    saveToFirestore(typeKey, updatedTypeData);
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    
    if (!destination) return;

    const sourceKey = source.droppableId;
    const destKey = destination.droppableId;
    
    if (sourceKey === destKey && source.index === destination.index) return;

    const sourceTypes = Array.from(appointmentTypes[sourceKey].subTypes || []);
    const destTypes = sourceKey === destKey 
      ? sourceTypes 
      : Array.from(appointmentTypes[destKey].subTypes || []);

    const [movedType] = sourceTypes.splice(source.index, 1);
    
    if (sourceKey === destKey) {
      sourceTypes.splice(destination.index, 0, movedType);
    } else {
      destTypes.splice(destination.index, 0, movedType);
    }

    const updatedSourceData = {
      ...appointmentTypes[sourceKey],
      subTypes: sourceTypes,
    };

    const updatedDestData = {
      ...appointmentTypes[destKey],
      subTypes: destTypes,
    };

    // Update localStorage
    const updatedUserData = {
      ...userData,
      user: {
        ...userData.user,
        settings: {
          ...userData.user.settings,
          appointmentTypes: {
            ...appointmentTypes,
            [sourceKey]: updatedSourceData,
            [destKey]: updatedDestData,
          }
        }
      }
    };
    
    setUserData(updatedUserData);
    localStorage.setItem('userData', JSON.stringify(updatedUserData));
    
    // Only update Firestore if moving between different macro groups
    if (sourceKey !== destKey) {
      try {
        await saveToFirestore(sourceKey, updatedSourceData);
        await saveToFirestore(destKey, updatedDestData);
      } catch (error) {
        console.error('❌ Failed to update appointment types:', error);
      }
    }
  };

  const handleAddSubType = (typeKey) => {
    const newSubType = prompt('Enter new sub-category name\n(Must match PIMs category exactly)');
    
    if (!newSubType?.trim()) return;

    const updatedTypeData = {
      ...appointmentTypes[typeKey],
      subTypes: [...(appointmentTypes[typeKey].subTypes || []), newSubType.trim()]
    };

    const updatedUserData = {
      ...userData,
      user: {
        ...userData.user,
        settings: {
          ...userData.user.settings,
          appointmentTypes: {
            ...appointmentTypes,
            [typeKey]: updatedTypeData
          }
        }
      }
    };

    setUserData(updatedUserData);
    localStorage.setItem('userData', JSON.stringify(updatedUserData));
    saveToFirestore(typeKey, updatedTypeData);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="primer-settings">
        <div className="card">
          <div className="settings-description">
            <p>Easily organize and personalize your appointment types:</p>
            <div className="instruction-list">
              <div className="instruction-item">
                <span className="icon">🎨</span>
                <span>Click the color squares to set unique colors for each category</span>
              </div>
              <div className="instruction-item">
                <span className="icon">↕️</span>
                <span>Drag and drop sub-categories to rearrange their order</span>
              </div>
            </div>
          </div>
          
          <div className="appointment-types-grid">
            {Object.entries(appointmentTypes).map(([key, type]) => (
              <Droppable droppableId={key} key={key}>
                {(provided, snapshot) => (
                  <div
                    className={`appointment-type-container ${
                      snapshot.isDraggingOver ? 'dragging-over' : ''
                    }`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="appointment-type-header">
                      <div className="color-picker-container">
                        <input
                          type="color"
                          value={type.color}
                          onChange={(e) => handleColorChange(key, e.target.value)}
                          className="color-picker"
                        />
                      </div>
                      <span className="type-name">{key}</span>
                    </div>
                    
                    <div className="sub-types-container">
                      {(type.subTypes || []).map((subType, index) => (
                        <Draggable
                          key={`${key}-${subType}`}
                          draggableId={`${key}-${subType}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`sub-type-item ${
                                snapshot.isDragging ? 'dragging' : ''
                              }`}
                              style={
                                snapshot.isDragging
                                  ? {
                                      ...provided.draggableProps.style,
                                      width: 'auto',
                                      left: 'auto',
                                    }
                                  : provided.draggableProps.style
                              }
                            >
                              {subType}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      <button 
                        className="add-subtype-button"
                        onClick={() => handleAddSubType(key)}
                      >
                        + Add Sub-category
                      </button>
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}

export default PrimerSettings;