import React, { useState } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db, auth } from '../config/firebase';
import '../styles/QuickPickPopup.css';

function AddQuickPickPopup({ onClose, onSubmit, reloadQuickPicks }) {
  const [label, setLabel] = useState('');
  const [question, setQuestion] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = auth.currentUser;
    if (user) {
      const quickPickId = label.replace(/\s+/g, '_').toLowerCase();
      const quickPickRef = doc(db, `users/${user.uid}/quickpicks/${quickPickId}`);

      try {
        // Save the new quick pick to Firestore
        await setDoc(quickPickRef, {
          label: label,
          question: question,
        });

        // Call the onSubmit to add the new quick pick
        onSubmit(label, question);

        // Reload quick picks from localStorage to ensure the latest state is used
        reloadQuickPicks();

        // Close the popup after saving
        onClose();
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    } else {
      console.error('No user is logged in.');
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h3>Add Quick Pick</h3>
        <form onSubmit={handleSubmit} className="popup-content">
          <div className="popup-fields">
            <input
              type="text"
              placeholder="Label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </div>
          <div className="popup-buttons">
            <button type="button" onClick={onClose} className="popup-button cancel-button">
              Cancel
            </button>
            <button type="submit" className="popup-button primary-button">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddQuickPickPopup;
