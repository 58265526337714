import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage'; // Import the storage module
import { signOut } from "firebase/auth";
import { getDatabase } from 'firebase/database'; // Import the Realtime Database

const firebaseConfig = {
  apiKey: "AIzaSyDozahTGejVbihJVEfRL1KJa67NPaSXR5Q",
  authDomain: "lyravet-ac8ca.firebaseapp.com",
  projectId: "lyravet-ac8ca",
  storageBucket: "lyravet-ac8ca.firebasestorage.app",
  messagingSenderId: "606783792729",
  appId: "1:606783792729:web:f3bc21036cf8ffc576b348",
  measurementId: "G-8PNBS77B1K"
};

const app = initializeApp(firebaseConfig);

// Auth
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Firestore
const db = getFirestore(app);

// Functions
const functions = getFunctions(app);

// Storage
const storage = getStorage(app); // Initialize Firebase Storage

const realTimeDb = getDatabase(app); // Initialize the Realtime Database instance

export { app, auth, signOut, googleProvider, realTimeDb, db, functions, storage };