const clinicalSignsSuggestions = [
  'Abdominal Pain',
  'Aggression',
  'Alopecia (Hair Loss)',
  'Anorexia',
  'Ataxia (Lack of Coordination)',
  'Behavior Changes',
  'Bleeding',
  'Blindness',
  'Bloody Diarrhea',
  'Bloody Urine (Hematuria)',
  'Breathing Difficulty (Dyspnea)',
  'Brittle Nails',
  'Bruising',
  'Cachexia (Weight Loss)',
  'Circling',
  'Collapse',
  'Constipation',
  'Coughing',
  'Cyanosis (Bluish Gums or Skin)',
  'Decreased Appetite',
  'Dehydration',
  'Depression',
  'Diarrhea',
  'Difficulty Swallowing (Dysphagia)',
  'Discharge from Eyes',
  'Discharge from Nose',
  'Disorientation',
  'Dizziness',
  'Drooling (Hypersalivation)',
  'Droopy Eyelid (Ptosis)',
  'Dry Cough',
  'Ear Discharge',
  'Ear Odor',
  'Excessive Drinking (Polydipsia)',
  'Excessive Grooming',
  'Excessive Scratching',
  'Excessive Thirst',
  'Excessive Urination (Polyuria)',
  'Facial Swelling',
  'Fainting (Syncope)',
  'Fever',
  'Flatulence',
  'Frequent Urination',
  'Head Shaking',
  'Head Tilt',
  'Hiding',
  'Hives (Urticaria)',
  'Hyperactivity',
  'Hyperesthesia (Increased Sensitivity to Touch)',
  'Hypersensitivity',
  'Increased Appetite',
  'Increased Heart Rate (Tachycardia)',
  'Increased Respiratory Rate',
  'Incoordination',
  'Increased Vocalization',
  'Incontinence',
  'Irritability',
  'Itching (Pruritus)',
  'Jaundice (Yellowing of Skin or Eyes)',
  'Lameness',
  'Lethargy',
  'Licking of Lips',
  'Limping',
  'Loss of Balance',
  'Loss of Consciousness',
  'Loss of Coordination',
  'Loss of Vision',
  'Loud Breathing (Stridor)',
  'Muscle Tremors',
  'Muscle Wasting',
  'Nasal Discharge',
  'Nausea',
  'Neck Pain',
  'Nystagmus (Rapid Eye Movement)',
  'Ocular Discharge',
  'Ocular Pain',
  'Open-Mouthed Breathing',
  'Painful Abdomen',
  'Panting',
  'Paralysis',
  'Pawing at Face',
  'Pica (Eating Non-Food Items)',
  'Polyphagia (Increased Appetite)',
  'Poor Coat Condition',
  'Pupil Dilation (Mydriasis)',
  'Pupil Constriction (Miosis)',
  'Rapid Breathing (Tachypnea)',
  'Rapid Heart Rate',
  'Reclusive Behavior',
  'Reduced Activity',
  'Regurgitation',
  'Restlessness',
  'Runny Nose',
  'Scooting',
  'Scratching',
  'Seizures',
  'Shivering',
  'Skin Lesions',
  'Skin Rash',
  'Sneezing',
  'Snoring',
  'Soft Stool',
  'Squinting',
  'Stiffness',
  'Straining to Defecate',
  'Straining to Urinate',
  'Sudden Collapse',
  'Swelling',
  'Swollen Lymph Nodes',
  'Tachypnea (Rapid Breathing)',
  'Tearing (Epiphora)',
  'Thickened Skin',
  'Tremors',
  'Twitching',
  'Uncoordinated Movements',
  'Unexplained Weight Loss',
  'Unusual Odor',
  'Urinating Outside the Litter Box',
  'Vomiting',
  'Weakness',
  'Weight Gain',
  'Weight Loss',
  'Wheezing',
  'Wincing in Pain',
  'Wobbling',
  'Wounds',
  'Yawning',
  'Yellow Gums',
  'Yellow Skin',
  'Zoonotic Disease Signs'
];

export default clinicalSignsSuggestions;
