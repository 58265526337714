import React, { memo } from "react";
import Primer from "./Primer";
import MainLayout from "../Layout/MainLayout";
import { useDoctor } from "../../contexts/DoctorContext";

interface PatientPrimerProps {
  refreshFirestoreData: () => Promise<void>;
  refreshTrigger: number;
}

const PatientPrimer: React.FC<PatientPrimerProps> = ({
  refreshFirestoreData,
  refreshTrigger,
}) => {
  const { selectedDoctor } = useDoctor();

  return (
    <MainLayout>
      <Primer
        selectedDoctor={selectedDoctor}
        refreshFirestoreData={refreshFirestoreData}
        refreshTrigger={refreshTrigger}
      />
    </MainLayout>
  );
};

export default memo(PatientPrimer);
