import React, { useState } from 'react';
import '../styles/QuickPickPopup.css';

function EditQuickPickPopup({ onClose, onUpdate, onDelete, quickPick }) {
  const [label, setLabel] = useState(quickPick.label);
  const [question, setQuestion] = useState(quickPick.question);

  const handleUpdate = () => {
    onUpdate(quickPick.id, label, question);
    onClose();
  };

  const handleDelete = () => {
    onDelete(quickPick.id);
    onClose();
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h3>Edit Quick Pick</h3>
        <div className="popup-content">
          <div className="popup-fields">
            <input
              type="text"
              placeholder="Label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </div>
          <div className="popup-buttons">
            <button type="button" onClick={onClose} className="popup-button cancel-button">
              Cancel
            </button>
            <button type="button" onClick={handleUpdate} className="popup-button primary-button">
              Update
            </button>
            <button type="button" onClick={handleDelete} className="popup-button delete-button">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditQuickPickPopup;