const dxSuggestions = [
  'Acute Kidney Injury',
  'Addisons Disease',
  'Allergic Dermatitis',
  'Anemia',
  'Anxiety Disorder',
  'Aortic Stenosis',
  'Arthritis',
  'Aspiration Pneumonia',
  'Atopic Dermatitis',
  'Bloat (Gastric Dilatation-Volvulus)',
  'Brain Tumor',
  'Bronchitis',
  'Cancer',
  'Cataracts',
  'Cerebellar Hypoplasia',
  'Cervical Disc Disease',
  'Cholangitis',
  'Cholecystitis',
  'Chronic Bronchitis',
  'Chronic Enteropathy',
  'Chronic Kidney Disease',
  'Chronic Pancreatitis',
  'Cirrhosis',
  'Cognitive Dysfunction Syndrome',
  'Colitis',
  'Congenital Heart Disease',
  'Congestive Heart Failure',
  'Conjunctivitis',
  'Corneal Ulcer',
  'Cushings Disease',
  'Cutaneous Lymphoma',
  'Degenerative Myelopathy',
  'Demodicosis',
  'Dental Disease',
  'Dermatitis',
  'Diabetes Insipidus',
  'Diabetes Mellitus',
  'Diarrhea',
  'Disc Herniation',
  'Distemper',
  'Ear Hematoma',
  'Ehrlichiosis',
  'Elbow Dysplasia',
  'Encephalitis',
  'Endocarditis',
  'Endocrine Alopecia',
  'Epilepsy',
  'EPI (Exocrine Pancreatic Insufficiency)',
  'Feline Asthma',
  'Feline Leukemia Virus (FeLV)',
  'Feline Lower Urinary Tract Disease (FLUTD)',
  'Feline Infectious Peritonitis (FIP)',
  'Feline Immunodeficiency Virus (FIV)',
  'Fibrosarcoma',
  'Flea Allergy Dermatitis',
  'Foreign Body Obstruction',
  'Fractures',
  'Gastric Ulcers',
  'Gastritis',
  'Gastroenteritis',
  'Glaucoma',
  'Heartworm Disease',
  'Hemangiosarcoma',
  'Hemolytic Anemia',
  'Hepatitis',
  'Hernia',
  'Hip Dysplasia',
  'Histiocytoma',
  'Horners Syndrome',
  'Hypertension',
  'Hyperadrenocorticism',
  'Hypercalcemia',
  'Hyperlipidemia',
  'Hyperparathyroidism',
  'Hyperthyroidism',
  'Hypertrophic Cardiomyopathy',
  'Hypocalcemia',
  'Hypoglycemia',
  'Hypoparathyroidism',
  'Hypothyroidism',
  'Ichthyosis',
  'Immune-Mediated Hemolytic Anemia (IMHA)',
  'Immune-Mediated Polyarthritis',
  'Inflammatory Bowel Disease (IBD)',
  'Intestinal Lymphoma',
  'Intussusception',
  'Keratoconjunctivitis Sicca (Dry Eye)',
  'Kidney Stones',
  'Laryngeal Paralysis',
  'Leptospirosis',
  'Liver Disease',
  'Liver Shunt (Portosystemic Shunt)',
  'Lymphoma',
  'Mast Cell Tumor',
  'Megaesophagus',
  'Melanoma',
  'Meningitis',
  'Mitral Valve Disease',
  'Myasthenia Gravis',
  'Nasal Tumors',
  'Neoplasia',
  'Osteoarthritis',
  'Osteochondritis Dissecans',
  'Osteomyelitis',
  'Otitis Externa',
  'Otitis Media',
  'Ovarian Cyst',
  'Pancreatitis',
  'Parvovirus',
  'Patellar Luxation',
  'Pneumonia',
  'Pneumothorax',
  'Polycythemia',
  'Polydipsia',
  'Polydactyly',
  'Polyneuropathy',
  'Portosystemic Shunt',
  'Prostatitis',
  'Pulmonary Edema',
  'Pulmonary Fibrosis',
  'Pyelonephritis',
  'Pyoderma',
  'Pyometra',
  'Renal Failure',
  'Respiratory Distress Syndrome',
  'Retinal Detachment',
  'Rhabdomyosarcoma',
  'Rocky Mountain Spotted Fever',
  'Salmonella',
  'Sarcoma',
  'Seizure Disorder',
  'Septicemia',
  'Spinal Cord Injury',
  'Splenic Tumors',
  'Stomatitis',
  'Syncope',
  'Tetanus',
  'Thrombocytopenia',
  'Tick-Borne Disease',
  'Toxoplasmosis',
  'Tracheal Collapse',
  'Transitional Cell Carcinoma',
  'Trauma',
  'Tremor Syndrome',
  'Ulcerative Colitis',
  'Urinary Incontinence',
  'Urinary Tract Infection (UTI)',
  'Ventricular Septal Defect',
  'Vestibular Disease',
  'Viral Enteritis',
  'Von Willebrand Disease',
  'Wobbler Syndrome'
];

export default dxSuggestions;
