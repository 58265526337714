import React, { useEffect, useState, useRef } from "react";
import { FaCheck, FaClock, FaPencilAlt } from "react-icons/fa";
import {
  ExclamationTriangleIcon,
  ClipboardDocumentListIcon,
  CheckCircleIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import { db } from "../../config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { usePatientStore } from "../../stores/patientStore";
import { click } from "@testing-library/user-event/dist/click";

interface Patient {
  id: string;
  name: string;
  breed: string;
  hasNote?: boolean;
  status?: "upcoming" | "inProgress" | "complete";
  alerts?: string[];
  patientData?: {
    medications?: Array<{
      name: string;
      dose: string;
      frequency: string;
      startDate: Date;
    }>;
    reminders?: {
      future: Array<{
        description: string;
        dueDate: Date;
        dateDifference: number;
      }>;
      overdue: Array<{
        description: string;
        dueDate: Date;
        dateDifference: number;
      }>;
      upcoming: Array<{
        description: string;
        dueDate: Date;
        dateDifference: number;
      }>;
    };
  };
}

interface Appointment {
  patient_id: string;
  time: string;
  type: string;
  appointmentTime?: Date;
  appointmentType?: string;
  duration?: number;
  patientData?: any;
  appointmentColor?: string;
  appointmentMacroGroup?: string;
  status?: "upcoming" | "inProgress" | "complete";
}

interface AppointmentTypeSettings {
  types?: string[];
  color?: string;
  group?: string;
}

interface AppointmentTypes {
  [key: string]: AppointmentTypeSettings;
}

interface PrimerPatientListItemProps {
  appointment: Appointment;
  patient: Patient;
  onClick: () => void;
  clickedCategory: string | null;
  appointmentTypes: AppointmentTypes;
}

const PrimerPatientListItem: React.FC<PrimerPatientListItemProps> = ({
  appointment,
  patient,
  onClick,
  clickedCategory,
  appointmentTypes,
}) => {
  const { selectedPrimerPatient, setSelectedPrimerPatient } = usePatientStore();
  const itemRef = useRef<HTMLDivElement>(null);
  const [hasPendingTasks, setHasPendingTasks] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const clinicId = userData.user?.clinicId;
  const userId = userData.user?.uid;

  // Add this check to determine if this item is selected
  const isItemSelected =
    selectedPrimerPatient &&
    selectedPrimerPatient.id === appointment.patient_id &&
    selectedPrimerPatient.appointmentTime === appointment.time;

  // Check for pending tasks on component mount
  useEffect(() => {
    const checkForPendingTasks = async () => {
      if (!clinicId || !userId || !patient?.id) {
        console.log("Missing required data:", {
          hasClinicId: !!clinicId,
          hasUserId: !!userId,
          hasPatientId: !!patient?.id,
        });
        return;
      }

      try {
        const tasksRef = collection(
          db,
          `clinics/${clinicId}/users/${userId}/tasks`
        );

        const q = query(
          tasksRef,
          where("patientId", "==", patient.id),
          where("status", "==", "pending")
        );

        const snapshot = await getDocs(q);

        setHasPendingTasks(!snapshot.empty);
      } catch (error) {
        console.error("Error checking for tasks:", error);
      }
    };

    checkForPendingTasks();
  }, [clinicId, userId, patient?.id]);

  // Add this effect to handle scrolling
  useEffect(() => {
    if (isItemSelected && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [isItemSelected]);

  const formatTimeToAMPM = (timeStr: any) => {
    if (!timeStr) return ""; // Early return for undefined/null

    // Handle Firebase Timestamp
    if (timeStr.seconds) {
      const date = new Date(timeStr.seconds * 1000);
      return date
        .toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        })
        .toLowerCase();
    }

    // Handle existing string formats
    if (
      timeStr.toLowerCase().endsWith("am") ||
      timeStr.toLowerCase().endsWith("pm")
    ) {
      return timeStr.toLowerCase();
    }

    if (timeStr.includes("T")) {
      const date = new Date(timeStr);
      return date
        .toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        })
        .toLowerCase();
    }

    // Handle HH:mm format
    if (typeof timeStr === "string") {
      let [hours, minutes] = timeStr.split(":").map(Number);
      const period = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12;
      return `${hours}:${minutes.toString().padStart(2, "0")}${period}`;
    }

    return ""; // Fallback for any other cases
  };

  const hasAlert = appointment.patientData.alerts.length > 0;

  const shouldShow =
    !clickedCategory ||
    appointment.appointmentMacroGroup === clickedCategory;

  const handleClick = () => {
    const updatedPatient = {
      ...patient,
      appointmentTime: appointment.time,
      appointmentType: appointment.type,
      id: appointment.patient_id,
    };
    setSelectedPrimerPatient(updatedPatient);
    onClick?.();
  };

  const getStatusIcon = () => {
    const status = appointment.patientData?.status || appointment.status || patient?.status;
    
    if (status === "complete") {
      return <CheckCircleIcon className="text-emerald-500 h-5 w-5" />;
    }
    if (status === "inProgress") {
      return <PencilIcon className="text-blue-500 h-5 w-5" />;
    }
    return null;
  };

  if (!shouldShow) return null;

  return (
    <div
      ref={itemRef}
      className={`primer-patient-item ${isItemSelected ? "selected" : ""}`}
      onClick={handleClick}
      data-patient-id={appointment.patient_id}
      data-time={appointment.time}
    >
      <div
        className="category-strip"
        style={{ backgroundColor: appointment.appointmentColor || "#A5B5BD" }}
      />
      <div className="patient-info">
        <div className="patient-name">
          {patient?.name} | {patient?.breed}
        </div>
        <div className="flex items-center gap-2 patient-time">
          <span>{formatTimeToAMPM(appointment.time)}</span>
          <span>•</span>
          <span>{appointment.type || appointment.appointmentType}</span>
        </div>
        <div className="status-indicators">
          {hasAlert && <ExclamationTriangleIcon className="alert-icon" />}
          {hasPendingTasks && (
            <ClipboardDocumentListIcon className="tasks-icon" />
          )}
          {getStatusIcon()}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PrimerPatientListItem);
