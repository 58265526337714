import React, { useState, useEffect } from 'react';
import '../styles/LinkCard.css';

interface LinkCardProps {
  url: string;
  title: string;
  description?: string;
  readTime?: string;
}

const LinkCard: React.FC<LinkCardProps> = ({ url, title, description, readTime }) => {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        // Using Microlink API - you'll need to sign up for an API key for production
        const response = await fetch(`https://api.microlink.io?url=${encodeURIComponent(url)}`);
        const data = await response.json();
        
        if (data.status === 'success' && data.data.image?.url) {
          setImageUrl(data.data.image.url);
        } else {
          // Fallback to a default image
          setImageUrl('/images/default-vet-preview.jpg');
        }
      } catch (error) {
        console.error('Error fetching metadata:', error);
        setImageUrl('/images/default-vet-preview.jpg');
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
  }, [url]);

  return (
    <a 
      href={url} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="link-card"
    >
      <div className="link-card-image">
        {loading ? (
          <div className="link-card-image-skeleton" />
        ) : (
          <img 
            src={imageUrl} 
            alt={title}
            onError={() => setImageUrl('/images/default-vet-preview.jpg')}
          />
        )}
      </div>
      <div className="link-card-content">
        <h3 className="link-card-title">{title}</h3>
        {description && (
          <p className="link-card-description">{description}</p>
        )}
        <div className="link-card-footer">
          <span className="link-card-domain">
            {new URL(url).hostname.replace('www.', '')}
          </span>
          {readTime && (
            <span className="link-card-read-time">{readTime}</span>
          )}
        </div>
      </div>
    </a>
  );
};

export default LinkCard; 