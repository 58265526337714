import React, { useState, useRef, useEffect } from "react";
import { format } from "date-fns";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import NewCommentForm from "../Comments/NewCommentForm";

interface CommentThreadProps {
  comment: {
    id: string;
    author: string;
    content: string;
    timestamp: Date;
    replies: any[];
    upvotes: number;
  };
  setComments: React.Dispatch<React.SetStateAction<any[]>>;
  depth?: number;
}

const CommentThread: React.FC<CommentThreadProps> = ({
  comment,
  setComments,
  depth = 0,
}) => {
  const [isReplying, setIsReplying] = useState(false);
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const replyFormRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isReplying && replyFormRef.current) {
      replyFormRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isReplying]);

  const handleReply = (content: string) => {
    const newReply = {
      id: Date.now().toString(),
      author: "Current User", // Replace with actual user data
      content,
      timestamp: new Date(),
      replies: [],
      upvotes: 0,
    };

    setComments((prevComments) => {
      const updateReplies = (comments: any[]): any[] => {
        return comments.map((c) => {
          if (c.id === comment.id) {
            return { ...c, replies: [newReply, ...c.replies] };
          }
          if (c.replies.length > 0) {
            return { ...c, replies: updateReplies(c.replies) };
          }
          return c;
        });
      };
      return updateReplies(prevComments);
    });

    setIsReplying(false);
  };

  const handleUpvote = () => {
    setIsUpvoted(!isUpvoted);
    setComments((prevComments) => {
      const updateUpvotes = (comments: any[]): any[] => {
        return comments.map((c) => {
          if (c.id === comment.id) {
            return {
              ...c,
              upvotes: c.upvotes + (isUpvoted ? -1 : 1),
            };
          }
          if (c.replies.length > 0) {
            return { ...c, replies: updateUpvotes(c.replies) };
          }
          return c;
        });
      };
      return updateUpvotes(prevComments);
    });
  };

  return (
    <div
      id={`comment-${comment.id}`}
      className="comment-thread-wrapper"
    >
      <div className="comment-indent-lines">
        {[...Array(depth)].map((_, i) => (
          <div key={i} className="indent-line" />
        ))}
      </div>

      <div className="comment-content-wrapper">
        <div className="comment">
          <div
            className="comment-collapse-button"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? (
              <ChevronRightIcon className="w-4 h-4" />
            ) : (
              <ChevronDownIcon className="w-4 h-4" />
            )}
          </div>

          <div className="comment-main">
            <div className="comment-header">
              <span className="comment-author">{comment.author}</span>
              <span className="comment-timestamp">
                {format(comment.timestamp, "MMM d, yyyy h:mm a")}
              </span>
            </div>

            {!isCollapsed && (
              <>
                <div className="comment-content">{comment.content}</div>
                <div className="comment-actions">
                  <button
                    className={`upvote-button ${isUpvoted ? "upvoted" : ""}`}
                    onClick={handleUpvote}
                  >
                    ↑ {comment.upvotes}
                  </button>
                  <button
                    className="reply-button"
                    onClick={() => setIsReplying(!isReplying)}
                  >
                    Reply
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        {!isCollapsed && (
          <>
            {isReplying && (
              <div ref={replyFormRef} className="reply-form">
                <NewCommentForm
                  onSubmit={handleReply}
                  onCancel={() => setIsReplying(false)}
                />
              </div>
            )}

            {comment.replies.length > 0 && (
              <div className="replies-wrapper">
                {comment.replies.map((reply) => (
                  <CommentThread
                    key={reply.id}
                    comment={reply}
                    setComments={setComments}
                    depth={depth + 1}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CommentThread;
