const exactDrugSuggestions = [
  'Acepromazine',
  'Acetylcysteine',
  'Albon (Sulfadimethoxine)',
  'Alfaxalone',
  'Allopurinol',
  'Alprazolam',
  'Amikacin',
  'Aminocaproic Acid',
  'Amiodarone',
  'Amitriptyline',
  'Amlodipine',
  'Amoxicillin',
  'Amoxicillin/Clavulanate (Clavamox)',
  'Ampicillin',
  'Anzemet (Dolasetron)',
  'Apomorphine',
  'Atenolol',
  'Atipamezole (Antisedan)',
  'Atropine',
  'Azathioprine',
  'Azithromycin',
  'Benazepril',
  'Bethanechol',
  'Bismuth Subsalicylate (Pepto-Bismol)',
  'Bupivacaine',
  'Buprenorphine',
  'Butorphanol',
  'Carprofen (Rimadyl)',
  'Cefazolin',
  'Cefovecin (Convenia)',
  'Cefpodoxime',
  'Cephalexin',
  'Chloramphenicol',
  'Chlorpromazine',
  'Chlorpheniramine',
  'Cimetidine',
  'Ciprofloxacin',
  'Cisapride',
  'Clindamycin',
  'Clomipramine (Clomicalm)',
  'Clonidine',
  'Clopidogrel',
  'Clotrimazole',
  'Cyclosporine (Atopica)',
  'Cyclophosphamide',
  'Dexamethasone',
  'Diazepam',
  'Dichlorphenamide (Daranide)',
  'Digoxin',
  'Diltiazem',
  'Diphenhydramine (Benadryl)',
  'Dipyrone',
  'Dobutamine',
  'Dopamine',
  'Doxycycline',
  'Enalapril',
  'Enrofloxacin (Baytril)',
  'Epinephrine',
  'Erythromycin',
  'Esmolol',
  'Famotidine (Pepcid)',
  'Fenbendazole (Panacur)',
  'Fentanyl',
  'Firocoxib (Previcox)',
  'Fluconazole',
  'Fluoxetine (Reconcile)',
  'Fluticasone',
  'Furosemide (Lasix)',
  'Gabapentin',
  'Gentamicin',
  'Glargine (Lantus)',
  'Glipizide',
  'Griseofulvin',
  'Guafenisin',
  'Hydralazine',
  'Hydrochlorothiazide',
  'Hydrocodone',
  'Hydromorphone',
  'Imidacloprid (Advantage)',
  'Imipramine',
  'Isoflurane',
  'Itraconazole',
  'Ketamine',
  'Ketoconazole',
  'Lactulose',
  'Levetiracetam (Keppra)',
  'Levothyroxine',
  'Lidocaine',
  'Loperamide',
  'Lorazepam',
  'Losartan',
  'Marbofloxacin (Zeniquin)',
  'Maropitant (Cerenia)',
  'Medetomidine',
  'Meloxicam (Metacam)',
  'Methimazole (Tapazole)',
  'Methocarbamol (Robaxin)',
  'Metoclopramide',
  'Metronidazole (Flagyl)',
  'Miconazole',
  'Milbemycin (Interceptor)',
  'Misoprostol',
  'Mitotane',
  'Morphine',
  'Moxidectin (ProHeart)',
  'Naloxone',
  'Neomycin',
  'Neostigmine',
  'Nitenpyram (Capstar)',
  'Nitrofurantoin',
  'Nitroglycerin',
  'Nitroprusside',
  'Novobiocin',
  'Omeprazole',
  'Ondansetron',
  'Oxacillin',
  'Oxytetracycline',
  'Oxytocin',
  'Pancuronium',
  'Penicillin G',
  'Pentobarbital',
  'Phenobarbital',
  'Phenoxybenzamine',
  'Phenylpropanolamine',
  'Phenylbutazone (Bute)',
  'Phytonadione (Vitamin K1)',
  'Pimobendan (Vetmedin)',
  'Polymyxin B',
  'Ponazuril',
  'Potassium Bromide',
  'Praziquantel (Droncit)',
  'Prednisolone',
  'Prednisone',
  'Procainamide',
  'Prochlorperazine',
  'Propofol',
  'Propranolol',
  'Pseudoephedrine',
  'Pyrimethamine',
  'Pyrantel (Strongid)',
  'Quinidine',
  'Ranitidine (Zantac)',
  'Rifampin',
  'Selegiline (Anipryl)',
  'Spironolactone',
  'Sucralfate',
  'Sulfamethoxazole/Trimethoprim (SMZ-TMP)',
  'Theophylline',
  'Tramadol',
  'Tylosin (Tylan)'
];

export default exactDrugSuggestions;
