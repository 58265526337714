import React, { useState } from "react";
import { CheckCircleIcon, ArrowRightIcon, PencilIcon } from "@heroicons/react/24/solid";

interface AppointmentTypeSettings {
  types?: string[];
  color?: string;
  group?: string;
}

interface AppointmentTypes {
  [key: string]: AppointmentTypeSettings;
}

interface Patient {
  id: string;
  name: string;
  appointmentTime?: string;
  appointmentType?: string;
  hasNote?: boolean;
  inProgress?: boolean;
}

interface PatientListItemProps {
  patient: Patient;
  status: "upcoming" | "inProgress" | "complete";
  onSelect?: (patient: Patient) => void;
  isSelected?: boolean;
  appointmentTypes?: AppointmentTypes;
  appointmentColor: string;
  onMoveToInProgress?: () => void;
  onToggleNote?: (patient: Patient) => void;
}

const PatientListItem: React.FC<PatientListItemProps> = ({
  patient,
  status,
  onSelect,
  isSelected = false,
  appointmentColor,
  onMoveToInProgress,
  onToggleNote,
}) => {
  // Get status-specific styles and icons
  const getStatusStyles = () => {
    switch (status) {
      case "upcoming":
        return {
          icon: null,
          stripColor: "#f59e0b",
        };
      case "inProgress":
        return {
          icon: <PencilIcon className="h-5 w-5 text-blue-500" />,
          stripColor: "#3b82f6",
        };
      case "complete":
        return {
          icon: <CheckCircleIcon className="h-5 w-5 text-emerald-500" />,
          stripColor: "#10b981",
        };
    }
  };

  const statusStyles = getStatusStyles();

  // Format appointment time to match PrimerPatientListItem
  const formatTime = (timeStr?: string): string => {
    if (!timeStr) return "";

    if (
      timeStr.toLowerCase().endsWith("am") ||
      timeStr.toLowerCase().endsWith("pm")
    ) {
      return timeStr.toLowerCase();
    }

    let [hours, minutes] = timeStr.split(":").map(Number);
    const period = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return `${hours}:${minutes.toString().padStart(2, "0")}${period}`;
  };

  // Simplify the handleMove function to be immediate
  const handleMove = (e: React.MouseEvent) => {
    e.stopPropagation();
    onMoveToInProgress?.();
  };

  return (
    <div
      className={`patient-list-item group w-[300px] ${
        isSelected ? "selected" : ""
      }`}
      onClick={() => onSelect?.(patient)}
    >
      <div
        className="category-strip"
        style={{ backgroundColor: appointmentColor }}
      />
      <div className="patient-info relative">
        {status === "upcoming" && (
          <button
            className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 bg-blue-500 rounded hidden group-hover:flex items-center justify-center hover:bg-blue-600 transition-colors"
            onClick={handleMove}
            title="Move to In Progress"
          >
            <ArrowRightIcon className="h-4 w-4 text-white" />
          </button>
        )}
        {status === "inProgress" && (
          <button
            className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 bg-emerald-500 rounded hidden group-hover:flex items-center justify-center hover:bg-emerald-600 transition-colors"
            onClick={(e) => {
              e.stopPropagation();
              onToggleNote?.(patient);
            }}
            title="Mark as Complete"
          >
            <CheckCircleIcon className="h-4 w-4 text-white" />
          </button>
        )}
        {status === "complete" && (
          <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center justify-center">
            <CheckCircleIcon className="h-5 w-5 text-emerald-500" />
          </div>
        )}
        <div className="flex items-center justify-between w-full pr-10">
          <div className="flex items-center gap-2">
            <span className="patient-name">{patient.name}</span>
          </div>
        </div>
        <div className="flex items-center gap-2 patient-time">
          {patient.appointmentTime && (
            <span>{formatTime(patient.appointmentTime)}</span>
          )}
          {patient.appointmentType && (
            <>
              <span>•</span>
              <span>{patient.appointmentType}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PatientListItem);
