import React, { useState, useRef, useEffect } from 'react';
import CommentThread from './CommentThread';
import NewCommentForm from './NewCommentForm';
import '../../styles/Comments.css';

interface Comment {
  id: string;
  author: string;
  content: string;
  timestamp: Date;
  replies: Comment[];
  upvotes: number;
}

const CommentSection: React.FC = () => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [lastCommentId, setLastCommentId] = useState<string | null>(null);
  const commentsEndRef = useRef<HTMLDivElement>(null);

  const scrollToComment = (commentId: string) => {
    const commentElement = document.getElementById(`comment-${commentId}`);
    if (commentElement) {
      commentElement.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'center' 
      });
    }
  };

  useEffect(() => {
    if (lastCommentId) {
      scrollToComment(lastCommentId);
    }
  }, [lastCommentId]);

  const handleNewComment = (content: string) => {
    const newComment: Comment = {
      id: Date.now().toString(),
      author: 'Current User', // Replace with actual user data
      content,
      timestamp: new Date(),
      replies: [],
      upvotes: 0
    };
    setComments(prevComments => [newComment, ...prevComments]);
    setLastCommentId(newComment.id);
  };

  return (
    <div className="comments-section">
      <div className="comments-header">
        <h2 className="comments-title">Discussion</h2>
        <span className="comments-count">
          {comments.length} {comments.length === 1 ? 'comment' : 'comments'}
        </span>
      </div>
      <NewCommentForm onSubmit={handleNewComment} />
      <div className="comments-list">
        {comments.map((comment) => (
          <CommentThread 
            key={comment.id} 
            comment={comment}
            setComments={setComments}
          />
        ))}
      </div>
      <div ref={commentsEndRef} />
    </div>
  );
};

export default CommentSection;
