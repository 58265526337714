import React, { useEffect, useState, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import type { Doctor } from "../../types/doctor";

interface DoctorSelectorProps {
  selectedDoctor: Doctor | null;
  onDoctorChange: (doctor: Doctor | null) => void;
}

const DoctorSelector: React.FC<DoctorSelectorProps> = ({
  selectedDoctor,
  onDoctorChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Load doctors from userData in localStorage
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");    
    const dailyData = userData.user?.missionBrief || {};
    
    // Extract unique doctors from the appointments data
    const formattedDoctors: Doctor[] = Object.entries(dailyData)
      .filter(([resourceId]) => !resourceId.toLowerCase().includes('clinic'))
      .map(([resourceId, data]: [string, any]) => ({
        value: resourceId,
        label: data.resourceName || 'Unknown Doctor',
        id: resourceId
      }));

    setDoctors(formattedDoctors);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleDoctorChange = (selectedOption: any) => {
    // Log the incoming data
    console.log("Selected doctor data:", selectedOption);

    if (!selectedOption) {
      onDoctorChange(null);
      return;
    }

    // Transform the data to match our Doctor type
    const doctor: Doctor = {
      id: selectedOption.value, // or wherever the id is coming from
      value: selectedOption.value,
      label: selectedOption.label,
    };

    onDoctorChange(doctor);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="flex items-center space-x-2 px-3 py-2 rounded-md 
                   text-deep-space transition-colors duration-150
                   border border-cosmic-cyan-40 bg-white
                   hover:bg-cosmic-cyan-10"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-sm text-cosmic-cyan-80">Resource:</span>
        <span className="font-medium">
          {selectedDoctor ? selectedDoctor.label : "Select Doctor"}
        </span>
        <ChevronDownIcon 
          className={`w-4 h-4 text-deep-space transition-transform duration-200
                     ${isOpen ? 'rotate-180' : ''}`} 
        />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 bg-white rounded-md 
                       shadow-lg z-50 border border-cosmic-cyan-40">
          <div className="py-1">
            {doctors.map((doctor) => (
              <button
                key={doctor.value}
                onClick={() => {
                  handleDoctorChange(doctor);
                  setIsOpen(false);
                }}
                className={`block w-full text-left px-4 py-2 text-sm 
                           transition-colors duration-150
                           hover:bg-cosmic-cyan-20 hover:text-deep-space
                           ${selectedDoctor?.value === doctor.value 
                             ? 'bg-cosmic-cyan-20 text-deep-space' 
                             : 'text-deep-space'}`}
              >
                {doctor.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorSelector;
