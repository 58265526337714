import React, { memo } from "react";
import Header from "../Header/Header";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="w-full py-4">{children}</main>
    </div>
  );
};

export default memo(MainLayout);
