import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ClipboardDocumentListIcon,
  PencilSquareIcon,
  UserGroupIcon,
  ChatBubbleBottomCenterTextIcon,
  BookOpenIcon,
  ChevronDownIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";
import ChatPanel from "../ChatPanel";
import TasksPanel from "../TasksPanel";

interface DropdownItem {
  label: string;
  path: string;
}

interface MenuItem {
  label: string;
  hasDropdown: boolean;
  dropdownItems?: DropdownItem[];
  path?: string;
  icon: React.ReactNode;
}

const Navigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [panelState, setPanelState] = useState({
    isChatOpen: false,
    isTasksOpen: false,
  });
  const [selectedChatPatient, setSelectedChatPatient] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  const menuItems: MenuItem[] = [
    {
      label: "Mission Brief",
      hasDropdown: false,
      path: "/PatientPrimer",
      icon: <ClipboardDocumentListIcon className="w-5 h-5 text-deep-space" />,
    },
    {
      label: "Scribe",
      hasDropdown: false,
      path: "/Scribe",
      icon: <PencilSquareIcon className="w-5 h-5 text-deep-space-40" />,
    },
    {
      label: "Chat",
      hasDropdown: false,
      path: "/Chat",
      icon: (
        <ChatBubbleBottomCenterTextIcon className="w-5 h-5 text-deep-space-40" />
      ),
    },
    {
      label: "Tasks",
      hasDropdown: false,
      path: "/Tasks",
      icon: (
        <ClipboardDocumentCheckIcon className="w-5 h-5 text-deep-space-40" />
      ),
    },
    {
      label: "Beacon",
      hasDropdown: true,
      dropdownItems: [
        { label: "Feed", path: "/beacon/feed" },
        { label: "Topic of the Week", path: "/beacon/topic" },
        { label: "Recommended Cases", path: "/beacon/recommended" },
        { label: "Open Cases", path: "/beacon/open" },
        { label: "Closed Cases", path: "/beacon/closed" },
        { label: "Direct Messages", path: "/beacon/messages" },
      ],
      icon: <UserGroupIcon className="w-5 h-5 text-deep-space-40" />,
    },
    {
      label: "Lyra's Log",
      hasDropdown: true,
      dropdownItems: [
        { label: "Monthly Transmission", path: "/log/monthly" },
        { label: "Release Notes", path: "/release-notes" },
      ],
      icon: <BookOpenIcon className="w-5 h-5 text-deep-space-40" />,
    },
  ];

  const handleNavigation = (path?: string, patient?: any) => {
    if (path === "/Chat") {
      setSelectedChatPatient(patient);
      setPanelState((prev) => ({
        ...prev,
        isChatOpen: !prev.isChatOpen,
      }));
    } else if (path === "/Tasks") {
      setPanelState((prev) => ({
        ...prev,
        isTasksOpen: !prev.isTasksOpen,
      }));
    } else if (path) {
      navigate(path);
      setActiveDropdown(null);
    }
  };

  const handleDropdownToggle = (label: string) => {
    setActiveDropdown(activeDropdown === label ? null : label);
  };

  const handlePanelToggle = (panel: "chat" | "tasks") => {
    setPanelState((prev) => ({
      ...prev,
      [panel === "chat" ? "isChatOpen" : "isTasksOpen"]:
        !prev[panel === "chat" ? "isChatOpen" : "isTasksOpen"],
    }));
  };

  const closeAllPanels = () => {
    setPanelState({
      isChatOpen: false,
      isTasksOpen: false,
    });
  };

  const isMenuItemActive = (item: MenuItem): boolean => {
    if (item.path === "/Chat") {
      return panelState.isChatOpen || location.pathname === item.path;
    }
    if (item.path === "/Tasks") {
      return panelState.isTasksOpen || location.pathname === item.path;
    }
    if (!item.hasDropdown) {
      return location.pathname === item.path;
    }
    return (
      item.dropdownItems?.some(
        (dropItem) => location.pathname === dropItem.path
      ) || false
    );
  };

  const getMenuItemStyles = (item: MenuItem): string => {
    const isActive = isMenuItemActive(item);
    const isPanelButton = item.path === "/Chat" || item.path === "/Tasks";
    const isPanelOpen =
      (item.path === "/Chat" && panelState.isChatOpen) ||
      (item.path === "/Tasks" && panelState.isTasksOpen);

    if (isActive && !isPanelButton) {
      return "bg-cosmic-cyan-20 ring-2 ring-cosmic-cyan-40";
    }
    if (isPanelOpen) {
      return "bg-cosmic-cyan-20 ring-1 ring-cosmic-cyan-20";
    }
    return "";
  };

  return (
    <>
      <nav className="flex space-x-4">
        {menuItems.map((item, index) => (
          <div key={index} className="relative group">
            <button
              className={`flex items-center space-x-2 px-3 py-2 rounded-md 
                         text-deep-space hover:bg-cosmic-cyan-20 
                         transition-colors duration-150
                         ${getMenuItemStyles(item)}`}
              onClick={() =>
                item.hasDropdown
                  ? handleDropdownToggle(item.label)
                  : handleNavigation(item.path)
              }
              data-menu-item={item.label}
            >
              {item.icon}
              <span className="font-medium hidden md:inline">{item.label}</span>
              {item.hasDropdown && (
                <ChevronDownIcon className="w-4 h-4 text-deep-space hidden md:inline" />
              )}
            </button>

            {item.hasDropdown && (
              <div
                className={`absolute left-0 mt-1 w-48 bg-white rounded-md shadow-lg 
                          transition-all duration-200 z-50
                          md:left-0 max-md:left-1/2 max-md:-translate-x-1/2
                          md:group-hover:opacity-100 md:group-hover:visible
                          ${
                            activeDropdown === item.label
                              ? "opacity-100 visible"
                              : "opacity-0 invisible"
                          }`}
              >
                <div className="py-2">
                  {item.dropdownItems?.map((dropdownItem, dropIndex) => (
                    <button
                      key={dropIndex}
                      onClick={() => handleNavigation(dropdownItem.path)}
                      className="block w-full text-left px-4 py-2 text-sm text-deep-space 
                               hover:bg-cosmic-cyan-20"
                    >
                      {dropdownItem.label}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </nav>

      <TasksPanel
        isOpen={panelState.isTasksOpen}
        onToggle={() => handlePanelToggle("tasks")}
        isChatOpen={panelState.isChatOpen}
      />
      <ChatPanel
        isOpen={panelState.isChatOpen}
        onToggle={() => handlePanelToggle("chat")}
        patient={selectedChatPatient}
      />
    </>
  );
};

export default Navigation;
