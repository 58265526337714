import React from 'react';
import MainLayout from '../components/Layout/MainLayout';
import LinkCard from '../components/LinkCard';
import CommentSection from '../components/Comments/CommentSection';
import '../styles/TopicOfWeek.css';

const TopicOfWeek: React.FC = () => {
  const externalLinks = [
    {
      url: 'https://www.vet.cornell.edu/departments-centers-and-institutes/cornell-feline-health-center/health-information/feline-health-topics/feline-diabetes',
      title: 'Understanding Feline Diabetes: A Comprehensive Guide',
      description: 'Cornell Feline Health Center explores the causes, symptoms, and treatment options for diabetes in cats.',
      readTime: '8 min read'
    },
    {
      url: 'https://catvets.com/guidelines/practice-guidelines/diabetes-guidelines',
      title: 'AAFP Guidelines: Managing Diabetic Cats',
      description: 'Latest clinical guidelines from the American Association of Feline Practitioners on diabetes management protocols.',
      readTime: '15 min read'
    },
    {
      url: 'https://icatcare.org/advice/diabetes-in-cats/',
      title: 'Home Monitoring of Diabetic Cats',
      description: 'International Cat Care provides practical advice for veterinarians on teaching clients to monitor diabetic cats at home.',
      readTime: '12 min read'
    }
  ];

  return (
    <MainLayout>
      <div className="topic-container">
        <div className="topic-header">
          <div className="header-image-container">
            <img 
              src="/topic-header.webp" 
              alt="Topic of the Week Header" 
              className="topic-header-image"
            />
          </div>
        </div>
        
        <div className="topic-content">
          <div className="topic-metadata">
            <span className="topic-category">Clinical Practice</span>
            <span className="topic-views">1,234 views</span>
            <span className="topic-comments">23 comments</span>
            <span className="topic-date">Nov 6, 2023</span>
          </div>

          <h1 className="topic-title">Managing Feline Diabetes: New Insights and Treatment Protocols</h1>
          
          <div className="topic-body">
            <section className="topic-section">
              <h2>The Rising Challenge of Feline Diabetes</h2>
              <p>
                With the increasing prevalence of obesity in domestic cats, veterinary practices are seeing a corresponding rise in feline diabetes cases. Recent studies suggest that up to 2% of cats will develop diabetes during their lifetime, with middle-aged and older cats being at highest risk.
              </p>
            </section>

            <section className="topic-section">
              <h2>Key Updates in Management Protocols</h2>
              <p>
                The landscape of feline diabetes management has evolved significantly in recent years. New research supports more individualized treatment approaches, with particular emphasis on:
              </p>
              <ul className="topic-list">
                <li>Early implementation of home blood glucose monitoring</li>
                <li>Use of longer-acting insulin preparations</li>
                <li>Integration of dietary management with insulin therapy</li>
                <li>Recognition and management of concurrent diseases</li>
              </ul>
            </section>

            <section className="topic-section">
              <h2>Clinical Significance</h2>
              <p>
                Understanding and implementing these updated protocols can significantly improve treatment outcomes. Recent studies show that with proper management, up to 80% of diabetic cats can achieve good glycemic control, with some even achieving diabetic remission.
              </p>
            </section>

            <section className="topic-section">
              <h2>Additional Resources</h2>
              <p>
                Below you'll find essential reading materials that delve deeper into specific aspects of feline diabetes management. These resources provide valuable insights for both general practitioners and specialists.
              </p>
            </section>

            <div className="external-links-grid">
              {externalLinks.map((link, index) => (
                <LinkCard
                  key={index}
                  url={link.url}
                  title={link.title}
                  description={link.description}
                  readTime={link.readTime}
                />
              ))}
            </div>
          </div>
        </div>

        <CommentSection />
      </div>
    </MainLayout>
  );
};

export default TopicOfWeek;