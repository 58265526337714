import React, { createContext, useContext, useEffect } from "react";
import type { Doctor } from "../types/doctor";
import { useDoctorStore } from "../stores/doctorStore";

interface DoctorContextType {
  selectedDoctor: Doctor | null;
  setSelectedDoctor: (doctor: Doctor | null) => void;
}

const DoctorContext = createContext<DoctorContextType | undefined>(undefined);

export const DoctorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { selectedDoctor, setSelectedDoctor } = useDoctorStore();

  // Single check on mount or when selectedDoctor changes
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    const hasMissionBrief = !!userData.user?.missionBrief;

  }, [selectedDoctor]);

  return (
    <DoctorContext.Provider value={{ selectedDoctor, setSelectedDoctor }}>
      {children}
    </DoctorContext.Provider>
  );
};

export const useDoctor = () => {
  const context = useContext(DoctorContext);
  if (context === undefined) {
    throw new Error("useDoctor must be used within a DoctorProvider");
  }
  return context;
};
