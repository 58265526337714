import React, { useState } from "react";
import { BellIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import AccountPopup from "../AccountPopup";

const SystemIcons: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isAccountPopupOpen, setIsAccountPopupOpen] = useState(false);
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handleAccountClick = () => {
    setShowDropdown(false);
    setIsAccountPopupOpen(true);
  };

  return (
    <>
      <div className="flex items-center space-x-4">
        <button className="relative hover:bg-cosmic-cyan-20 p-2 rounded-md transition-colors duration-150">
          <BellIcon className="h-5 w-5 text-deep-space-40" />
          <span className="absolute top-1.5 right-1.5 h-2 w-2 bg-martian rounded-full"></span>
        </button>

        <div className="relative">
          <button
            className="flex items-center space-x-3 hover:bg-cosmic-cyan-20 p-2 rounded-md transition-colors duration-150"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <div className="h-8 w-8 flex items-center justify-center text-lg bg-cosmic-cyan-20 rounded-full">
              👤
            </div>
            <div className="hidden md:flex flex-col items-start">
              <span className="text-sm text-deep-space font-semibold">
                Nic Herfel
              </span>
              <span className="text-xs text-deep-space-40">Data Scientist</span>
            </div>
          </button>

          {/* Dropdown Menu */}
          {showDropdown && (
            <div className="absolute right-0 mt-4 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
              <div className="py-2" role="menu" aria-orientation="vertical">
                <button
                  onClick={handleAccountClick}
                  className="w-full text-left px-4 py-2 text-sm text-deep-space hover:bg-cosmic-cyan-20 transition-colors duration-150"
                  role="menuitem"
                >
                  Account Settings
                </button>
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-sm text-deep-space hover:bg-cosmic-cyan-20 transition-colors duration-150"
                  role="menuitem"
                >
                  Sign Out
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <AccountPopup
        user={currentUser}
        isOpen={isAccountPopupOpen}
        onClose={() => setIsAccountPopupOpen(false)}
        refreshFirestoreData={() => {}}
      />
    </>
  );
};

export default SystemIcons;
