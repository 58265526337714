import React from 'react';
import MainLayout from '../components/Layout/MainLayout';
import '../styles/ReleaseNotes.css';

interface ReleaseNote {
  version: string;
  date: string;
  features: string[];
  improvements: string[];
  fixes: string[];
}

const releaseNotes: ReleaseNote[] = [
  {
    version: "1.1.0",
    date: "March 20, 2024",
    features: [
      "Added voice-to-text SOAP note generation",
      "Introduced patient history tracking",
      "New appointment scheduling system",
      "Integration with major practice management systems",
      "Real-time collaboration features for veterinary teams"
    ],
    improvements: [
      "Enhanced SOAP note template customization",
      "Improved user interface for medical scribe",
      "Optimized data synchronization across devices"
    ],
    fixes: [
      "Fixed issue with note saving",
      "Resolved appointment calendar sync problems",
      "Addressed performance issues in patient search"
    ]
  },
  {
    version: "1.0.2",
    date: "March 1, 2024",
    features: [
      "Quick-access templates for common procedures",
      "Patient photo attachment capability"
    ],
    improvements: [
      "Faster loading times for patient records",
      "Enhanced search functionality",
      "Updated UI elements for better visibility",
      "Improved mobile responsiveness across all features"
    ],
    fixes: [
      "Fixed data validation issues in form submissions",
      "Resolved browser compatibility issues",
      "Fixed printing formatting issues"
    ]
  },
  {
    version: "1.0.1",
    date: "February 25, 2024",
    features: [],
    improvements: [
      "Enhanced security protocols",
      "Improved data backup system",
      "Updated third-party integrations"
    ],
    fixes: [
      "Critical security patch implementation",
      "Fixed login issues on certain devices",
      "Resolved data synchronization conflicts"
    ]
  },
  {
    version: "1.0.0",
    date: "February 15, 2024",
    features: [
      "Initial release of Lyra Vet platform",
      "Basic SOAP note creation and management",
      "Patient record system",
      "Appointment scheduling",
      "Secure user authentication",
      "Cloud-based data storage",
      "Basic reporting functionality"
    ],
    improvements: [],
    fixes: []
  }
];

const ReleaseNotes: React.FC = () => {
  return (
    <MainLayout>
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-[#1B1464] mb-8 sticky top-0 bg-gray-50 py-4 z-10">
          Release Notes
        </h1>
        
        <div className="relative">
          <div className="grid gap-6 max-h-[calc(100vh-12rem)] overflow-y-auto custom-scrollbar pr-6 -mr-6">
            {releaseNotes.map((note) => (
              <div 
                key={note.version}
                className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-200"
              >
                <div className="px-6 py-4 bg-gradient-to-r from-[#1B1464] to-[#00BCD4]">
                  <div className="flex items-center justify-between">
                    <h2 className="text-2xl font-bold text-white">
                      Version {note.version}
                    </h2>
                    <span className="text-sm font-medium text-white/90">{note.date}</span>
                  </div>
                </div>

                <div className="px-6 py-4">
                  {note.features.length > 0 && (
                    <div className="mb-6">
                      <h3 className="text-xl font-semibold text-[#00BCD4] mb-4">
                        New Features
                      </h3>
                      <ul className="space-y-3">
                        {note.features.map((feature, i) => (
                          <li key={i} className="flex items-start">
                            <span className="text-[#00BCD4] mr-3 mt-1.5">
                              <svg className="w-2 h-2" fill="currentColor" viewBox="0 0 8 8">
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                            </span>
                            <span className="text-gray-700 text-lg">{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {note.improvements.length > 0 && (
                    <div className="mb-6">
                      <h3 className="text-xl font-semibold text-[#1B1464] mb-4">
                        Improvements
                      </h3>
                      <ul className="space-y-3">
                        {note.improvements.map((improvement, i) => (
                          <li key={i} className="flex items-start">
                            <span className="text-[#1B1464] mr-3 mt-1.5">
                              <svg className="w-2 h-2" fill="currentColor" viewBox="0 0 8 8">
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                            </span>
                            <span className="text-gray-700 text-lg">{improvement}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {note.fixes.length > 0 && (
                    <div className="mb-6">
                      <h3 className="text-xl font-semibold text-[#1B1464] mb-4">
                        Bug Fixes
                      </h3>
                      <ul className="space-y-3">
                        {note.fixes.map((fix, i) => (
                          <li key={i} className="flex items-start">
                            <span className="text-[#1B1464] mr-3 mt-1.5">
                              <svg className="w-2 h-2" fill="currentColor" viewBox="0 0 8 8">
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                            </span>
                            <span className="text-gray-700 text-lg">{fix}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ReleaseNotes;