import React from "react";

const Logo: React.FC = () => {
  return (
    <div className="flex items-center space-x-2">
      <img src="/lyravet-logo.svg" alt="Patient Primer" className="h-16 w-32" />
    </div>
  );
};

export default Logo;
