import React, { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import '../../styles/SettingsStyles/ScribeSettings.css';
import { FiInfo } from 'react-icons/fi';
import { debounce } from 'lodash';

const ScribeSettings = ({ user }) => {
  const [userData, setUserData] = useState(() => 
    JSON.parse(localStorage.getItem('userData') || '{}')
  );
  const clinicId = userData.user?.clinicId;
  
  const [settings, setSettings] = useState({
    soapNoteAdditions: '',
    personalSoapNoteTemplate: '',
    usingSoapNoteDefaultTemplate: true,
    clientDischargeAdditions: '',
    personalDischargeTemplate: '',
    usingClientDischargeDefaultTemplate: true
  });
  const [isLoading, setIsLoading] = useState(true);
  const [showSoapInfo, setShowSoapInfo] = useState(false);
  const [showDischargeInfo, setShowDischargeInfo] = useState(false);
  const [saveMessage, setSaveMessage] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      if (!user || !clinicId) return;
      
      try {
        const settingsRef = doc(db, 'clinics', clinicId, 'users', user.uid, 'settings', 'scribeSettings');
        const settingsDoc = await getDoc(settingsRef);
        
        if (settingsDoc.exists()) {
          setSettings(settingsDoc.data());
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, [user, clinicId]);

  const handleTemplateToggle = async (type) => {
    const newSettings = {
      ...settings,
      [type === 'soap' ? 'usingSoapNoteDefaultTemplate' : 'usingClientDischargeDefaultTemplate']: 
        !settings[type === 'soap' ? 'usingSoapNoteDefaultTemplate' : 'usingClientDischargeDefaultTemplate']
    };
    setSettings(newSettings);
    await saveSettings(newSettings, type);
  };

  const handleTemplateChange = async (type, value) => {
    const newSettings = {
      ...settings,
      [type === 'soap' ? 'soapNoteAdditions' : 'clientDischargeAdditions']: value
    };
    setSettings(newSettings);
    await debouncedSave(newSettings, type);
  };

  const saveSettings = async (newSettings, type) => {
    if (!user || !clinicId) return;

    try {
      const settingsRef = doc(db, 'clinics', clinicId, 'users', user.uid, 'settings', 'scribeSettings');
      await setDoc(settingsRef, newSettings, { merge: true });
      
      setSaveMessage({ type, message: 'Changes saved successfully' });
      setTimeout(() => {
        setSaveMessage(null);
      }, 3000);
    } catch (error) {
      console.error('Error saving settings:', error);
      setSaveMessage({ type, message: `Error: ${error.message}` });
    }
  };

  const debouncedSave = useCallback(
    debounce((newSettings, type) => {
      if (!user || !clinicId) return;

      const settingsRef = doc(db, 'clinics', clinicId, 'users', user.uid, 'settings', 'scribeSettings');
      setDoc(settingsRef, newSettings, { merge: true })
        .then(() => {
          setSaveMessage({ type, message: 'Changes saved successfully' });
          setTimeout(() => {
            setSaveMessage(null);
          }, 3000);
        })
        .catch((error) => {
          console.error('Error saving settings:', error);
          setSaveMessage({ type, message: `Error: ${error.message}` });
        });
    }, 1000),
    [user, clinicId]
  );

  const SaveMessage = ({ message, type }) => (
    message && (
      <div className={`save-message ${message.includes('Error') ? 'error' : 'success'}`}>
        {message}
      </div>
    )
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="primer-settings">
      <div className="card">
        <div className="settings-section">
          <h3>SOAP Notes Settings</h3>
          <div className="template-selection">
            <div className="template-option">
              <input
                type="radio"
                id="soapGlobal"
                name="soapTemplate"
                checked={settings.usingSoapNoteDefaultTemplate}
                onChange={() => handleTemplateToggle('soap')}
              />
              <label htmlFor="soapGlobal">Vetreum's Best Practice Template</label>
              <FiInfo 
                className="info-icon"
                onMouseEnter={() => setShowSoapInfo('global')}
                onMouseLeave={() => setShowSoapInfo(null)}
              />
            </div>
            <div className="template-option">
              <input
                type="radio"
                id="soapPersonal"
                name="soapTemplate"
                checked={!settings.usingSoapNoteDefaultTemplate}
                onChange={() => handleTemplateToggle('soap')}
              />
              <label htmlFor="soapPersonal">Digital Persona Template</label>
              <FiInfo 
                className="info-icon"
                onMouseEnter={() => setShowSoapInfo('personal')}
                onMouseLeave={() => setShowSoapInfo(null)}
              />
            </div>
          </div>
          
          {showSoapInfo === 'global' && (
            <div className="info-text">
              Our AI-powered template combines the most effective documentation practices 
              from thousands of SOAP notes across Vetreum's network of clinics.
            </div>
          )}
          
          {showSoapInfo === 'personal' && (
            <div className="info-text">
              A smart template created from your digital persona, automatically learning 
              and adapting to your unique documentation style and preferences.
            </div>
          )}

          <div className="custom-template">
            <h4>Custom Template Addition</h4>
            <p className="template-subheader">These instructions will be added to your selected template above.</p>
            <textarea
              value={settings.soapNoteAdditions}
              onChange={(e) => handleTemplateChange('soap', e.target.value)}
              placeholder="Examples:
• 'Include detailed history of current medications and supplements in the subjective section'
• 'Add differential diagnoses in order of likelihood in the assessment section'"
              className="template-input"
            />
            {saveMessage?.type === 'soap' && (
              <SaveMessage message={saveMessage.message} type="soap" />
            )}
          </div>
        </div>

        <div className="settings-section">
          <h3>Discharge Notes Settings</h3>
          <div className="template-selection">
            <div className="template-option">
              <input
                type="radio"
                id="dischargeGlobal"
                name="dischargeTemplate"
                checked={settings.usingClientDischargeDefaultTemplate}
                onChange={() => handleTemplateToggle('discharge')}
              />
              <label htmlFor="dischargeGlobal">Vetreum's Best Practice Template</label>
              <FiInfo 
                className="info-icon"
                onMouseEnter={() => setShowDischargeInfo('global')}
                onMouseLeave={() => setShowDischargeInfo(null)}
              />
            </div>
            <div className="template-option">
              <input
                type="radio"
                id="dischargePersonal"
                name="dischargeTemplate"
                checked={!settings.usingClientDischargeDefaultTemplate}
                onChange={() => handleTemplateToggle('discharge')}
              />
              <label htmlFor="dischargePersonal">Digital Persona Template</label>
              <FiInfo 
                className="info-icon"
                onMouseEnter={() => setShowDischargeInfo('personal')}
                onMouseLeave={() => setShowDischargeInfo(null)}
              />
            </div>
          </div>

          {showDischargeInfo === 'global' && (
            <div className="info-text">
              Our AI-powered template combines the most effective documentation practices 
              from thousands of client discharge notes across Vetreum's network of clinics.
            </div>
          )}
          
          {showDischargeInfo === 'personal' && (
            <div className="info-text">
              A smart template created from your digital persona, automatically learning 
              and adapting to your unique documentation style and preferences.
            </div>
          )}

          <div className="custom-template">
            <h4>Custom Template Addition</h4>
            <p className="template-subheader">These instructions will be added to your selected template above.</p>
            <textarea
              value={settings.clientDischargeAdditions}
              onChange={(e) => handleTemplateChange('discharge', e.target.value)}
              placeholder="Examples:
• 'Include specific exercise restrictions with duration if applicable'
• 'Add medication schedule with feeding instructions when necessary'"
              className="template-input"
            />
            {saveMessage?.type === 'discharge' && (
              <SaveMessage message={saveMessage.message} type="discharge" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScribeSettings;