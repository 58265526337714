import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import type { Doctor } from '../types/doctor'

interface DoctorState {
  selectedDoctor: Doctor | null;
  setSelectedDoctor: (doctor: Doctor | null) => void;
  initializeDefaultDoctor: () => void;
}

export const useDoctorStore = create<DoctorState>()(
  persist(
    (set) => ({
      selectedDoctor: null,
      setSelectedDoctor: (doctor: Doctor | null) => set({ selectedDoctor: doctor }),
      initializeDefaultDoctor: () => {
        const userData = JSON.parse(localStorage.getItem("userData") || "{}");
        const dailyData = userData.user?.missionBrief || {};
        
        // Get the first doctor from the missionBrief data
        const firstDoctorEntry = Object.entries(dailyData)[0];
        
        if (firstDoctorEntry) {
          const [resourceId, data] = firstDoctorEntry as [string, { resourceName?: string }];
          const defaultDoctor: Doctor = {
            id: resourceId,
            value: resourceId,
            label: (data?.resourceName as string) || 'Unknown Doctor',
          };
          set({ selectedDoctor: defaultDoctor });
        }
      },
    }),
    {
      name: 'doctor-storage',
    }
  )
) 