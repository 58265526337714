import React from "react";
import "../styles/LoadingScreen.css";

const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-screen">
      <div className="stars-background" />
      <div className="logo-container">
        <img
          src="/lyravet-logo-only.svg"
          alt="Lyra Vet"
          className="floating-logo"
        />
        <div className="pulse-dots">
          <div className="pulse-dot"></div>
          <div className="pulse-dot"></div>
          <div className="pulse-dot"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
