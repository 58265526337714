interface RecordingGroup {
  startTime: number;
  duration: number;
}

export class AudioStorageService {
  private db: IDBDatabase | null = null;
  private readonly DB_NAME = 'AudioStorage';
  private readonly STORE_NAME = 'recordings';

  async initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log('Initializing AudioStorage...');
      const request = indexedDB.open(this.DB_NAME, 1);

      request.onerror = () => {
        console.error('Failed to open IndexedDB:', request.error);
        reject(request.error);
      };

      request.onsuccess = () => {
        this.db = request.result;
        this.cleanupOldRecordings();
        resolve();
      };

      request.onupgradeneeded = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains(this.STORE_NAME)) {
          const store = db.createObjectStore(this.STORE_NAME, { keyPath: 'id' });
          store.createIndex('timestamp', 'timestamp', { unique: false });
          store.createIndex('patientId', 'patientId', { unique: false });
        }
      };
    });
  }

  async saveRecording(
    patientId: string, 
    audioBlob: Blob, 
    durationInSeconds: number, 
    timestamp: number = Date.now()
  ): Promise<void> {
    if (!this.db) throw new Error('Database not initialized');
    console.log('Saving recording for patient:', patientId, 'duration:', durationInSeconds);

    const transaction = this.db.transaction(this.STORE_NAME, 'readwrite');
    const store = transaction.objectStore(this.STORE_NAME);

    return new Promise((resolve, reject) => {
      const recordingData = {
        id: `${patientId}_${timestamp}`,
        patientId,
        audio: audioBlob,
        duration: durationInSeconds,
        timestamp: timestamp
      };

      const request = store.put(recordingData);

      request.onerror = () => {
        console.error('Failed to save recording:', request.error);
        reject(request.error);
      };
      request.onsuccess = () => {
        console.log('Successfully saved recording with duration');
        resolve();
      };
    });
  }

  async getRecordings(patientId: string): Promise<Blob[]> {
    if (!this.db) throw new Error('Database not initialized');
    console.log('Getting recordings for patient:', patientId);

    const transaction = this.db.transaction(this.STORE_NAME, 'readonly');
    const store = transaction.objectStore(this.STORE_NAME);
    const index = store.index('patientId');

    return new Promise((resolve, reject) => {
      const request = index.getAll(IDBKeyRange.only(patientId));
      
      request.onerror = () => {
        console.error('Failed to get recordings:', request.error);
        reject(request.error);
      };

      request.onsuccess = () => {
        const recordings = request.result;
        console.log(`Found ${recordings.length} recordings for patient:`, patientId);
        resolve(recordings.map(record => record.audio));
      };
    });
  }

  async verifyStorage(): Promise<void> {
    if (!this.db) throw new Error('Database not initialized');

    const transaction = this.db.transaction(this.STORE_NAME, 'readonly');
    const store = transaction.objectStore(this.STORE_NAME);
    const request = store.getAll();

    request.onsuccess = () => {
      const allRecordings = request.result;
      console.log('All recordings in storage:', allRecordings.map(rec => ({
        id: rec.id,
        patientId: rec.patientId,
        mimeType: rec.mimeType,
        timestamp: new Date(rec.timestamp)
      })));
    };
  }

  private async cleanupOldRecordings(): Promise<void> {
    if (!this.db) return;

    const RETENTION_PERIOD_MS = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    const sevenDaysAgo = Date.now() - RETENTION_PERIOD_MS;
    const transaction = this.db.transaction(this.STORE_NAME, 'readwrite');
    const store = transaction.objectStore(this.STORE_NAME);
    const index = store.index('timestamp');

    const request = index.openCursor(IDBKeyRange.upperBound(sevenDaysAgo));
    
    request.onsuccess = (event) => {
      const cursor = (event.target as IDBRequest).result;
      if (cursor) {
        store.delete(cursor.primaryKey);
        cursor.continue();
      }
    };
  }

  async convertToWAV(blobs: Blob[]): Promise<Blob> {
    return new Blob(blobs, { type: 'audio/wav' });
  }

  async getTotalDuration(blobs: Blob[]): Promise<number> {
    let totalDuration = 0;
    
    for (const blob of blobs) {
      const duration = await this.getAudioDuration(blob);
      totalDuration += duration;
    }
    
    return totalDuration;
  }

  private getAudioDuration(blob: Blob): Promise<number> {
    return new Promise((resolve, reject) => {
      const audioUrl = URL.createObjectURL(blob);
      const audio = new Audio(audioUrl);
      
      audio.addEventListener('loadedmetadata', () => {
        const duration = audio.duration;
        URL.revokeObjectURL(audioUrl);
        resolve(duration);
      });
      
      audio.addEventListener('error', (error) => {
        URL.revokeObjectURL(audioUrl);
        reject(error);
      });
    });
  }

  async getRecordingGroups(patientId: string): Promise<RecordingGroup[]> {
    if (!this.db) throw new Error('Database not initialized');

    const transaction = this.db.transaction(this.STORE_NAME, 'readonly');
    const store = transaction.objectStore(this.STORE_NAME);
    const index = store.index('patientId');

    return new Promise((resolve, reject) => {
      const request = index.getAll(IDBKeyRange.only(patientId));
      
      request.onerror = () => reject(request.error);
      request.onsuccess = () => {
        const recordings = request.result;
        
        // Sort recordings by timestamp
        recordings.sort((a, b) => a.timestamp - b.timestamp);
        
        // Group recordings with less than 5 minutes gap
        const FIVE_MINUTES = 5 * 60 * 1000; // 5 minutes in milliseconds
        const groups: RecordingGroup[] = [];
        let currentGroup: RecordingGroup | null = null;

        recordings.forEach(recording => {
          if (!currentGroup) {
            currentGroup = {
              startTime: recording.timestamp,
              duration: recording.duration
            };
          } else {
            const timeSinceLastRecording = recording.timestamp - (currentGroup.startTime + currentGroup.duration * 1000);
            
            if (timeSinceLastRecording > FIVE_MINUTES) {
              // Start new group
              groups.push(currentGroup);
              currentGroup = {
                startTime: recording.timestamp,
                duration: recording.duration
              };
            } else {
              // Add to current group
              currentGroup.duration += recording.duration;
            }
          }
        });

        // Add last group if exists
        if (currentGroup) {
          groups.push(currentGroup);
        }

        resolve(groups);
      };
    });
  }
}

export const audioStorage = new AudioStorageService(); 