import React from "react";
import PatientExplorer from "../pages/PatientExplorer";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { usePatientStore } from '../stores/patientStore';

interface ChatPanelProps {
  isOpen: boolean;
  onToggle: () => void;
  patient?: any;
}

const ChatPanel: React.FC<ChatPanelProps> = ({ isOpen, onToggle, patient }) => {
  // Get the selected patient from the store
  const selectedPatient = usePatientStore(state => state.selectedPatient);
  
  return (
    <>
      <div
        className={`
          fixed top-[20px] bottom-[20px] right-[20px] z-50
          w-[90vw] max-w-xl bg-[#f8fafc]
          transform transition-all duration-500 ease-out
          ${
            isOpen
              ? "translate-x-0 opacity-100"
              : "translate-x-[100px] opacity-0 pointer-events-none"
          }
          shadow-[0_8px_30px_rgb(0,0,0,0.12),0_4px_15px_rgb(0,0,0,0.12)]
          rounded-2xl border border-gray-100
          flex flex-col
        `}
      >
        <div className="absolute -right-3 -top-3 z-[9999]">
          <button
            onClick={onToggle}
            className="p-2 rounded-full
                      bg-white shadow-md
                      border border-gray-200/70 
                      hover:bg-red-50 
                      transition-colors
                      group"
          >
            <XMarkIcon className="w-4 h-4 text-gray-500 group-hover:text-red-500" />
          </button>
        </div>

        <div className="flex-1">
          <PatientExplorer
            llmMode="patient-explorer"
            context="chat"
            initialPatient={patient || selectedPatient}
          />
        </div>
      </div>
    </>
  );
};

export default ChatPanel;
