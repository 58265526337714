import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import '../styles/PatientFilterContainer.css';

function PatientFilterContainer({ selectedPatient, onPatientSelect, selectedDoctor, onDoctorSelect, isPrimerPage, setShowPatientFilter, isOpen: externalIsOpen }) {
  const [selectedPatientState, setSelectedPatientState] = useState(null);
  const [selectedDoctorState, setSelectedDoctorState] = useState(null);
  const [scheduleBased, setScheduleBased] = useState(false);
  const [internalIsOpen, setInternalIsOpen] = useState(externalIsOpen !== undefined ? externalIsOpen : true);
  const location = useLocation();
  const containerRef = useRef(null);

  const [patientOptions, setPatientOptions] = useState([]);
  const [doctorOptions, setDoctorOptions] = useState([]);

  useEffect(() => {
    const dailyPrimerData = JSON.parse(localStorage.getItem('dailyPrimerData') || '{}');
    
    if (dailyPrimerData.patients && dailyPrimerData.patients.patients) {
      const patients = dailyPrimerData.patients.patients;
      const options = patients.map(patient => ({
        value: patient.id.toString(),
        label: `${patient.pims_id} | ${patient.name} | ${patient.breed}`,
        patient: patient,
      }));
      setPatientOptions(options);
      
      // Set default patient if none is selected
      if (!selectedPatient && options.length > 0) {
        const defaultPatient = options[0];
        onPatientSelect(defaultPatient);
        setSelectedPatientState(defaultPatient);
        localStorage.setItem('selectedPatient', JSON.stringify(defaultPatient.patient));
      }
    }

    if (dailyPrimerData.doctors && dailyPrimerData.doctors.doctors) {
      const doctors = dailyPrimerData.doctors.doctors;
      const options = doctors.map(doctor => ({
        value: doctor.id.toString(),
        label: doctor.name,
      }));
      setDoctorOptions(options);
      
      // Set default doctor if none is selected and onDoctorSelect is provided
      if (!selectedDoctor && options.length > 0 && typeof onDoctorSelect === 'function') {
        const defaultDoctor = options[0];
        onDoctorSelect(defaultDoctor);
      }
    }
  }, [selectedPatient, selectedDoctor, onPatientSelect, onDoctorSelect]);

  useEffect(() => {
    if (selectedPatient) {
      setSelectedPatientState(selectedPatient);
    } else {
      const savedPatient = localStorage.getItem('selectedPatient');
      if (savedPatient) {
        const parsedPatient = JSON.parse(savedPatient);
        const matchingOption = patientOptions.find(option => option.value === parsedPatient.id.toString());
        if (matchingOption) {
          setSelectedPatientState(matchingOption);
          onPatientSelect(matchingOption);
        }
      } else if (patientOptions.length > 0) {
        const defaultPatient = patientOptions[0];
        setSelectedPatientState(defaultPatient);
        onPatientSelect(defaultPatient);
        localStorage.setItem('selectedPatient', JSON.stringify(defaultPatient.patient));
      }
    }
  }, [selectedPatient, patientOptions, onPatientSelect]);

  useEffect(() => {
    if (selectedDoctor) {
      setSelectedDoctorState(doctorOptions.find(option => option.value === selectedDoctor.value));
    }
  }, [selectedDoctor, doctorOptions]);

  const defaultDoctor = doctorOptions.length > 0 ? doctorOptions[0] : null;
  const defaultPatient = patientOptions.length > 0 ? patientOptions[0] : null;

  useEffect(() => {
    if (selectedDoctor) {
      setSelectedDoctorState(selectedDoctor);
    } else if (defaultDoctor) {
      setSelectedDoctorState(defaultDoctor);
      if (typeof onDoctorSelect === 'function') {
        onDoctorSelect(defaultDoctor);
      }
      localStorage.setItem('selectedDoctor', JSON.stringify(defaultDoctor));
    }
  
    if (selectedPatient) {
      setSelectedPatientState(selectedPatient);
    } else {
      const savedPatient = localStorage.getItem('selectedPatient');
      if (savedPatient) {
        const parsedPatient = JSON.parse(savedPatient);
        const matchingOption = patientOptions.find(option => option.value === parsedPatient.PmsId);
        setSelectedPatientState(matchingOption || null);
      } else if (defaultPatient) {
        setSelectedPatientState(defaultPatient);
        onPatientSelect(defaultPatient);
        localStorage.setItem('selectedPatient', JSON.stringify(defaultPatient.patient));
      }
    }
  }, [selectedDoctor, selectedPatient, onDoctorSelect, onPatientSelect]);

  useEffect(() => {
    if (externalIsOpen !== undefined) {
      setInternalIsOpen(externalIsOpen);
    }
  }, [externalIsOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        closeFilter();
      }
    };

    if (internalIsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (internalIsOpen) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [internalIsOpen]);

  const closeFilter = () => {
    if (typeof setShowPatientFilter === 'function') {
      setShowPatientFilter(false);
    }
    setInternalIsOpen(false);
  };

  const handlePatientSelect = (selectedOption) => {
    setSelectedPatientState(selectedOption);
    onPatientSelect(selectedOption);
    localStorage.setItem('selectedPatient', JSON.stringify(selectedOption ? selectedOption.patient : null));
    window.dispatchEvent(new CustomEvent('patientSelected', { detail: selectedOption ? selectedOption.patient : null }));
    closeFilter();
  };

  const handleDoctorSelect = (selectedOption) => {
    setSelectedDoctorState(selectedOption);
    if (typeof onDoctorSelect === 'function') {
      onDoctorSelect(selectedOption);
    }
    localStorage.setItem('selectedDoctor', selectedOption ? selectedOption.value : '');
    closeFilter();
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '5px',
      border: '1px solid #ccc',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #3B82F6',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3B82F6' : state.isFocused ? '#93C5FD' : 'white',
      color: state.isSelected ? 'white' : 'inherit',
      cursor: 'pointer',
      '&:active': {
        backgroundColor: state.isSelected ? '#3B82F6' : '#93C5FD',
      },
    }),
  };

  if (!internalIsOpen) {
    return null;
  }

  return (
    <div className="patient-filter-container" ref={containerRef}>
      <div className="bottom-row">
        <div className="filter-item">
          {isPrimerPage ? (
            <Select
              value={selectedDoctorState}
              onChange={handleDoctorSelect}
              options={doctorOptions}
              placeholder="Select a doctor"
              isClearable
              isSearchable
              isDisabled={scheduleBased}
              styles={customStyles}
            />
          ) : (
            <Select
              value={selectedPatientState}
              onChange={handlePatientSelect}
              options={patientOptions}
              placeholder="Select a patient"
              isClearable
              isSearchable
              isDisabled={scheduleBased}
              styles={customStyles}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientFilterContainer;