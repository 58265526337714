import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  FaMicrophone,
  FaStop,
  FaTrash,
  FaEdit,
  FaSpinner,
  FaChevronUp,
  FaChevronDown,
  FaCopy,
  FaSave,
  FaArrowLeft,
} from "react-icons/fa";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  FirebaseStorage,
} from "firebase/storage";
import {
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
  Firestore,
} from "firebase/firestore";
import { Auth } from "firebase/auth";
import "../styles/MedicalScribe.css";
import { LiveAudioVisualizer, AudioVisualizer } from "react-audio-visualize";
import { audioStorage } from "../services/AudioStorageService";
import { useDoctorStore } from "../stores/doctorStore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Patient } from "../stores/patientStore";
import HistoricalNote from "./HistoricalNote";

const functions = getFunctions();
const assemblysoap = httpsCallable(functions, "assemblysoap", {
  timeout: 540000, // 9 minutes timeout
});

interface MedicalScribeProps {
  soapMode: "spoken" | "written";
  auth: Auth;
  storage: FirebaseStorage;
  db: Firestore;
  selectedPatient: Patient | null;
  currentStaticInfo?: StaticInfo | null;
  refreshFirestoreData: () => Promise<void>;
  onStartNote?: () => void;
}

interface StaticInfo {
  formattedDate: string;
  time: string;
  patientName: string;
  patientBreed: string;
}

interface DoctorData {
  value: string;
  label: string;
  // Add other doctor properties as needed
}

interface DailyPrimerData {
  patients?: {
    patients?: Patient[];
  };
}

interface AudioVisualizerProps {
  mediaRecorder: MediaRecorder;
  width: number;
  height: number;
  barColor: string;
}

// Add this type near your other interfaces
type AssemblySoapResponse = {
  data: string;
};

interface NoteData {
  // Define the structure of your note data here
}
const getCurrentDateString = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Returns YYYY-MM-DD
};

const MedicalScribe = forwardRef<any, MedicalScribeProps>(
  (
    {
      soapMode,
      auth,
      storage,
      db,
      selectedPatient,
      currentStaticInfo,
      refreshFirestoreData,
      onStartNote,
    },
    forwardedRef
  ) => {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [writtenInput, setWrittenInput] = useState<string>("");
    const [additionalDetails, setAdditionalDetails] = useState<string>("");
    const [showTrashIcon, setShowTrashIcon] = useState<boolean>(false);
    const [showGenerateNote, setShowGenerateNote] = useState<boolean>(false);
    const [hasStoppedRecording, setHasStoppedRecording] =
      useState<boolean>(false);
    const [hasInteractedWithTextArea, setHasInteractedWithTextArea] =
      useState<boolean>(false);
    const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
    const [generatedSOAP, setGeneratedSOAP] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [accumulatedTime, setAccumulatedTime] = useState<number>(0);
    const [noteTitle, setNoteTitle] = useState<string>("");
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [showInputs, setShowInputs] = useState<boolean>(true);
    const [isEditingSOAP, setIsEditingSOAP] = useState<boolean>(false);
    const [editedSOAP, setEditedSOAP] = useState<string>("");
    const [copyButtonText, setCopyButtonText] = useState<string>("Copy Text");
    const [isNoteEdited, setIsNoteEdited] = useState<boolean>(false);
    const [docId, setDocId] = useState<string | null>(null);
    const [selectedPatientData, setSelectedPatientData] =
      useState<Patient | null>(null);
    const [selectedDoctorData, setSelectedDoctorData] =
      useState<DoctorData | null>(null);
    const [isMicrophoneTesting, setIsMicrophoneTesting] =
      useState<boolean>(false);
    const [audioLevel, setAudioLevel] = useState<number>(0);
    const [isMicrophoneWorking, setIsMicrophoneWorking] =
      useState<boolean>(true);
    const [microphoneTestRecorder, setMicrophoneTestRecorder] =
      useState<MediaRecorder | null>(null);
    const [generateProgress, setGenerateProgress] = useState<number>(0);
    const [isInitialized, setIsInitialized] = useState(false);
    const [existingNote, setExistingNote] = useState<NoteData | null>(null);
    const [showNoteInputs, setShowNoteInputs] = useState<boolean>(false);

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const startTimeRef = useRef<number | null>(null);

    const doctorStore = useDoctorStore();

    useEffect(() => {
      const storedPatientStr = localStorage.getItem("selectedPatient");
      if (storedPatientStr) {
        const storedPatient = JSON.parse(storedPatientStr);
        setSelectedPatientData(storedPatient);
      }
    }, []);

    useEffect(() => {
      const savedDoctorStr = localStorage.getItem("selectedDoctor");
      if (savedDoctorStr) {
        const savedDoctor = JSON.parse(savedDoctorStr);
        setSelectedDoctorData(savedDoctor);
      }
    }, []);

    useImperativeHandle(forwardedRef, () => ({
      handleDiscard,
    }));

    useEffect(() => {
      if (selectedPatient) {
        setSelectedPatientData(selectedPatient); // Update patient data when selectedPatient changes
      }
    }, [selectedPatient]);

    useEffect(() => {
      return () => {
        if (timerIntervalRef.current) {
          clearInterval(timerIntervalRef.current);
        }
        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
        }
      };
    }, []);

    useEffect(() => {
      setDefaultTitle();
    }, [selectedPatient, currentStaticInfo]);

    useEffect(() => {
      const checkForExistingNote = async () => {
        if (!selectedPatient?.id) return;

        const userId = auth.currentUser?.uid;
        if (!userId) return;

        // Get clinic ID from localStorage
        const userData = JSON.parse(localStorage.getItem("userData") || "{}");
        const clinicId = userData?.user?.clinicId;
        if (!clinicId) {
          console.error("No clinic ID found");
          return;
        }

        const pimsId = selectedPatient.pims_id || selectedPatient.id;
        const currentDate = getCurrentDateString();

        try {
          const noteRef = doc(
            db,
            "clinics",
            clinicId,
            "users",
            userId,
            "scribe",
            currentDate,
            "dailyNotes",
            pimsId
          );

          const noteDoc = await getDoc(noteRef);
          if (noteDoc.exists()) {
            const noteData = noteDoc.data() as NoteData;
            setExistingNote(noteData);

            // Update the patient's hasNote status in the mission brief
            const missionBriefRef = doc(
              db,
              `clinics/${clinicId}/missionBrief/daily`
            );
            const missionBriefDoc = await getDoc(missionBriefRef);

            if (missionBriefDoc.exists()) {
              const missionBriefData = missionBriefDoc.data();
              const selectedDoctor = doctorStore.selectedDoctor;

              if (
                selectedDoctor?.value &&
                missionBriefData[selectedDoctor.value]?.appointments
              ) {
                const updatedAppointments = missionBriefData[
                  selectedDoctor.value
                ].appointments.map((apt: any) => {
                  if (apt.patientData?.patientId === pimsId) {
                    return {
                      ...apt,
                      hasNote: true,
                      patientData: {
                        ...apt.patientData,
                        hasNote: true,
                      },
                    };
                  }
                  return apt;
                });

                await updateDoc(missionBriefRef, {
                  [`${selectedDoctor.value}.appointments`]: updatedAppointments,
                });
              }
            }
          } else {
            setExistingNote(null);
          }
        } catch (error) {
          console.error("Error checking for existing note:", error);
        }
      };

      checkForExistingNote();
    }, [selectedPatient, auth.currentUser, db]);

    const setDefaultTitle = () => {
      if (selectedPatient && currentStaticInfo) {
        setNoteTitle(
          `${selectedPatient.name} | ${currentStaticInfo.formattedDate} | ${currentStaticInfo.time}`
        );
      } else if (currentStaticInfo) {
        setNoteTitle(
          `Unknown | ${currentStaticInfo.formattedDate} | ${currentStaticInfo.time}`
        );
      } else {
        setNoteTitle("Unknown");
      }
    };

    const formatTime = (timeInSeconds: number): string => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    };

    const updateElapsedTime = () => {
      if (startTimeRef.current) {
        const currentTime = Date.now();
        const delta = (currentTime - startTimeRef.current) / 1000;
        setElapsedTime(accumulatedTime + delta);
      }
    };

    useEffect(() => {
      audioStorage
        .initialize()
        .then(() => setIsInitialized(true))
        .catch((error) =>
          console.error("Failed to initialize audio storage:", error)
        );
    }, []);

    const handleRecordingToggle = async () => {
      if (!isRecording) {
        onStartNote?.();
      }
      if (isRecording && mediaRecorderRef.current) {
        // Stop recording
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        setHasStoppedRecording(true);
        setShowTrashIcon(true);
        setShowGenerateNote(true);

        // Clear the timer interval
        if (timerIntervalRef.current) {
          clearInterval(timerIntervalRef.current);
          timerIntervalRef.current = null;
        }

        // Add current session time to accumulated time
        if (startTimeRef.current) {
          const currentTime = Date.now();
          const sessionTime = (currentTime - startTimeRef.current) / 1000;
          setAccumulatedTime((prev) => prev + sessionTime);
        }
        startTimeRef.current = null;

        // Save the recording chunk
        if (audioChunks.length > 0 && selectedPatient?.id) {
          try {
            const audioBlob = new Blob(audioChunks, {
              type: mediaRecorderRef.current.mimeType,
            });
            await audioStorage.saveRecording(
              selectedPatient.id,
              audioBlob,
              elapsedTime
            );
            setAudioChunks([]); // Clear chunks after saving
          } catch (error) {
            console.error("Failed to save recording:", error);
          }
        }

        // Stop stream tracks but don't reset accumulated time
        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
          streamRef.current = null;
        }
        mediaRecorderRef.current = null;
      } else {
        // Start/Resume recording
        try {
          if (!streamRef.current) {
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            streamRef.current = stream;
          }

          const mimeType = getSupportedMimeType();
          const recorder = new MediaRecorder(streamRef.current, {
            mimeType: mimeType,
          });
          mediaRecorderRef.current = recorder;

          recorder.ondataavailable = async (e) => {
            setAudioChunks((prevChunks) => [...prevChunks, e.data]);
          };

          recorder.start(1000);
          setIsRecording(true);
          setShowTrashIcon(false);
          setShowGenerateNote(false);

          // Start timer from current accumulated time
          startTimeRef.current = Date.now();
          timerIntervalRef.current = setInterval(updateElapsedTime, 1000);
        } catch (error) {
          console.error("Error accessing the microphone:", error);
          setIsMicrophoneWorking(false);
          alert("An error occurred while trying to access your microphone.");
        }
      }
    };

    // Add this helper function to get supported MIME type
    const getSupportedMimeType = (): string => {
      const types = [
        "audio/webm",
        "audio/webm;codecs=opus",
        "audio/ogg;codecs=opus",
        "audio/mp4",
      ];

      for (const type of types) {
        if (MediaRecorder.isTypeSupported(type)) {
          return type;
        }
      }

      throw new Error("No supported audio MIME types found");
    };

    const handleInputFocus = () => {
      if (!hasInteractedWithTextArea) {
        onStartNote?.();
        setHasInteractedWithTextArea(true);
        setShowTrashIcon(true);
        setShowGenerateNote(true);
      }
    };

    const handleDiscard = () => {
      // Clear recording-related state
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      setElapsedTime(0);
      setAccumulatedTime(0);
      setAudioChunks([]);
      setIsRecording(false);
      setHasStoppedRecording(false);
      startTimeRef.current = null;
      streamRef.current = null;
      mediaRecorderRef.current = null;

      // Clear written input and related state
      setWrittenInput("");
      setAdditionalDetails("");
      setShowTrashIcon(false);
      setShowGenerateNote(false);
      setHasInteractedWithTextArea(false);

      // Clear any generated content
      setGeneratedSOAP("");
      setEditedSOAP("");
      setShowInputs(true);
      setIsNoteEdited(false);
    };

    const getUserId = (): string => {
      if (!auth.currentUser) {
        throw new Error("No authenticated user");
      }
      return auth.currentUser.uid;
    };

    const simulateProgress = () => {
      setGenerateProgress(0);

      const interval = setInterval(() => {
        setGenerateProgress((prev) => {
          if (prev >= 90) {
            clearInterval(interval);
            return 90;
          }
          if (prev < 30) {
            return prev + 0.8; // Slower start
          } else if (prev < 60) {
            return prev + 1.2; // Faster middle
          } else {
            return prev + 0.7; // Slower end
          }
        });
      }, 200);

      return interval;
    };

    const handleGenerateNote = async () => {
      setIsLoading(true);
      setIsGenerating(true);
      const progressInterval = simulateProgress();
      let currentProgress = 0;

      try {
        // Get clinic data from localStorage
        const userData = JSON.parse(localStorage.getItem("userData") || "{}");
        const clinicId = userData?.user?.clinicId;

        if (!clinicId) {
          throw new Error("No clinic ID available in user data");
        }

        // Use the retrieved clinicId
        const missionBriefRef = doc(
          db,
          `clinics/${clinicId}/missionBrief/daily`
        );

        // Get clinic data
        const missionBriefDoc = await getDoc(missionBriefRef);
        const missionBriefData = missionBriefDoc.data();

        // Declare soapInput variable
        let soapInput: string;

        // Get doctor data directly from the store
        const selectedDoctor = doctorStore.selectedDoctor;
        if (!selectedDoctor?.value) {
          throw new Error("No doctor selected");
        }

        // Validate user authentication
        if (!auth.currentUser) {
          throw new Error("No authenticated user");
        }
        const userId = auth.currentUser.uid;

        // Validate patient data
        if (!selectedPatientData) {
          throw new Error("No patient data available");
        }
        const pims_id =
          selectedPatientData.pims_id?.toString() ||
          selectedPatientData.id.toString();
        if (!pims_id) {
          throw new Error("Invalid patient ID");
        }
        console.log("[SCRIBE] Using pims_id:", pims_id);
        setDocId(pims_id);

        // Update the patient's status in missionBrief
        if (missionBriefData && selectedDoctorData?.value) {
          const doctorId = selectedDoctorData.value;
          const updatedAppointments = missionBriefData[
            doctorId
          ].appointments.map((apt: any) => {
            if (apt.patientData?.patientId === pims_id) {
              return {
                ...apt,
                hasNote: true,
                patientData: {
                  ...apt.patientData,
                  hasNote: true,
                },
              };
            }
            return apt;
          });

          await updateDoc(missionBriefRef, {
            [`${doctorId}.appointments`]: updatedAppointments,
          });
        }

        if (soapMode === "written") {
          soapInput = writtenInput;
          if (!soapInput.trim()) {
            throw new Error("No input available for written SOAP note");
          }

          // Generate note using regular fetch
          const generatedNoteResponse = await fetch(
            "https://us-central1-lyravet-ac8ca.cloudfunctions.net/createDraftSOAP",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                transcription: soapInput,
                userId: userId,
                patientData: selectedPatientData,
                doctorData: selectedDoctor,
                additionalDetails: additionalDetails,
              }),
            }
          );

          if (!generatedNoteResponse.ok) {
            throw new Error(`API error: ${generatedNoteResponse.status}`);
          }

          const generatedNote = await generatedNoteResponse.text();

          // Stop the progress simulation and get current value
          clearInterval(progressInterval);
          currentProgress = generateProgress;

          // Brief pause at current progress
          await new Promise((resolve) => setTimeout(resolve, 200));

          // Complete the progress smoothly using the same animation as spoken notes
          await new Promise<void>((resolve) => {
            const startProgress = currentProgress;
            const steps = 20; // More steps for smoother animation
            const stepDuration = 30; // Faster steps
            let step = 0;

            const finishInterval = setInterval(() => {
              step++;
              // Use the same easeOut function for smooth animation
              const progress =
                startProgress +
                (100 - startProgress) * (1 - Math.pow(1 - step / steps, 3));
              setGenerateProgress(progress);

              if (step >= steps) {
                clearInterval(finishInterval);
                setGenerateProgress(100);
                resolve();
              }
            }, stepDuration);
          });

          // Small delay before showing the result
          await new Promise((resolve) => setTimeout(resolve, 100));

          setGeneratedSOAP(generatedNote);
          setEditedSOAP(generatedNote);
          setShowInputs(false);
          setIsNoteEdited(false);
        } else if (soapMode === "spoken" && selectedPatient?.id) {
          try {
            console.log("[SCRIBE] Starting spoken note generation process");

            // Get all recordings for this patient
            const recordings = await audioStorage.getRecordings(
              selectedPatient.id
            );
            console.log("[SCRIBE] Retrieved recordings:", recordings.length);

            // Start progress simulation
            const progressInterval = simulateProgress();
            let currentProgress = 0;

            try {
              // Upload each recording and get URLs
              const audioUrls = [];
              console.log("[SCRIBE] Starting audio uploads");

              for (const recording of recordings) {
                console.log("[SCRIBE] Uploading recording to Firebase Storage");
                const audioRef = storageRef(
                  storage,
                  `audio/${userId}/${selectedPatient.id}/${Date.now()}.wav`
                );
                await uploadBytes(audioRef, recording);
                const audioUrl = await getDownloadURL(audioRef);
                audioUrls.push(audioUrl);
                console.log(
                  "[SCRIBE] Successfully uploaded recording, URL obtained"
                );
              }

              console.log("[SCRIBE] All recordings uploaded. URLs:", audioUrls);

              // Call assemblysoap
              console.log("[SCRIBE] Calling assemblysoap with payload:", {
                audioFiles: audioUrls,
                userId: userId,
                clinicId: clinicId,
                patientData: {
                  name: selectedPatientData.name || "Unknown",
                  pimsId: pims_id,
                  breed: selectedPatientData.breed || "Unknown",
                  appointmentTime: selectedPatientData.appointmentTime,
                  appointmentType: selectedPatientData.appointmentType,
                },
                doctorData: selectedDoctor,
                additionalDetails: additionalDetails,
              });

              const result = (await assemblysoap({
                audioFiles: audioUrls,
                userId: userId,
                clinicId: clinicId,
                patientData: {
                  pimsId: pims_id,
                  name: selectedPatientData.name || "Unknown",
                  breed: selectedPatientData.breed || "Unknown",
                  appointmentTime: selectedPatientData.appointmentTime,
                  appointmentType: selectedPatientData.appointmentType,
                },
                doctorData: selectedDoctor,
                additionalDetails: additionalDetails,
              })) as AssemblySoapResponse;

              console.log(
                "[SCRIBE] Received response from assemblysoap:",
                result
              );

              // Stop the progress simulation and get current value
              clearInterval(progressInterval);
              currentProgress = generateProgress;
              console.log("[SCRIBE] Current progress:", currentProgress);

              // Brief pause at current progress
              await new Promise((resolve) => setTimeout(resolve, 200));

              // Complete the progress smoothly
              await new Promise<void>((resolve) => {
                const startProgress = currentProgress;
                console.log(
                  "[SCRIBE] Starting completion animation from:",
                  startProgress
                );

                const steps = 20;
                const stepDuration = 30;
                let step = 0;

                const finishInterval = setInterval(() => {
                  step++;
                  const progress =
                    startProgress +
                    (100 - startProgress) * (1 - Math.pow(1 - step / steps, 3));
                  setGenerateProgress(progress);

                  if (step >= steps) {
                    clearInterval(finishInterval);
                    setGenerateProgress(100);
                    console.log("[SCRIBE] Completion animation finished");
                    resolve();
                  }
                }, stepDuration);
              });

              // Small delay before showing the result
              await new Promise((resolve) => setTimeout(resolve, 100));

              console.log("[SCRIBE] Setting UI state with generated note");
              setGeneratedSOAP(result.data);
              setEditedSOAP(result.data);
              setShowInputs(false);
              setIsNoteEdited(false);
              console.log("[SCRIBE] Note generation process complete");
            } catch (error) {
              console.error("[SCRIBE] Error during note generation:", error);
              clearInterval(progressInterval);
              throw error;
            }
          } catch (error) {
            console.error("[SCRIBE] Failed to process recordings:", error);
            throw error;
          }
        } else {
          throw new Error("No input available");
        }
      } catch (error) {
        clearInterval(progressInterval);
        console.error("Error generating SOAP note:", error);
        alert(
          `Error generating SOAP note: ${
            error instanceof Error ? error.message : "Unknown error"
          }`
        );
      } finally {
        setIsLoading(false);
        setIsGenerating(false);
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setWrittenInput(e.target.value);
      if (e.target.value.trim() !== "") {
        setShowTrashIcon(true);
      }
    };

    const handleAdditionalDetailsChange = (
      e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      setAdditionalDetails(e.target.value);
    };

    const toggleInputs = () => {
      setShowInputs(!showInputs);
    };

    const handleReturnToInputs = () => {
      setShowInputs(true);
    };

    const handleCopyText = () => {
      navigator.clipboard.writeText(editedSOAP);
      setCopyButtonText("Text Copied!");
      setTimeout(() => setCopyButtonText("Copy Text"), 2000);
    };

    const handleEditSOAP = () => {
      setIsEditingSOAP(true);
    };

    const handleSOAPChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setEditedSOAP(e.target.value);
      setIsNoteEdited(true);
    };

    const handleSaveSOAP = async () => {
      setIsLoading(true);
      try {
        const userId = getUserId();
        if (!docId) {
          throw new Error("No document ID available");
        }
        const quickSOAPDocRef = doc(db, "users", userId, "quickSOAP", docId);

        await updateDoc(quickSOAPDocRef, {
          draftSOAP: editedSOAP,
        });

        setGeneratedSOAP(editedSOAP);
        setIsNoteEdited(false);
        setShowNoteInputs(false);
      } catch (error) {
        console.error("Error saving SOAP note:", error);
        alert("An error occurred while saving the SOAP note.");
      } finally {
        setIsLoading(false);
      }
    };

    const handleMicrophoneTest = async () => {
      try {
        if (isMicrophoneTesting) {
          if (microphoneTestRecorder) {
            microphoneTestRecorder.stop();
          }
          setIsMicrophoneTesting(false);
          setMicrophoneTestRecorder(null);
        } else {
          try {
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            const recorder = new MediaRecorder(stream);

            // Set up audio analysis
            const audioContext = new AudioContext();
            const audioSource = audioContext.createMediaStreamSource(stream);
            const analyser = audioContext.createAnalyser();
            analyser.fftSize = 256; // Smaller FFT size for more responsive readings
            audioSource.connect(analyser);

            // Monitor audio levels with adjusted threshold
            const checkAudioLevel = () => {
              const dataArray = new Uint8Array(analyser.frequencyBinCount);
              analyser.getByteFrequencyData(dataArray);

              // Calculate RMS value for more accurate level detection
              const rms = Math.sqrt(
                dataArray.reduce((sum, value) => sum + value * value, 0) /
                  dataArray.length
              );

              setAudioLevel(rms);
              // Lower threshold to 1 for more sensitive detection
              setIsMicrophoneWorking(rms > 1);

              if (isMicrophoneTesting) {
                requestAnimationFrame(checkAudioLevel);
              }
            };

            checkAudioLevel();
            recorder.start();
            setMicrophoneTestRecorder(recorder);
            setIsMicrophoneTesting(true);
          } catch (permissionError) {
            alert("Please allow microphone permission in extension settings");
            setIsMicrophoneWorking(false);
            return;
          }
        }
      } catch (error) {
        console.error("Error testing microphone:", error);
        setIsMicrophoneWorking(false);
      }
    };

    const renderMicrophoneTest = () => (
      <button
        className={`microphone-test-button ${
          isMicrophoneTesting ? "testing" : ""
        }`}
        onClick={handleMicrophoneTest}
      >
        {isMicrophoneTesting ? "Stop Test" : "Test Microphone"}
      </button>
    );

    const getActiveRecorder = (): MediaRecorder | null => {
      if (isRecording && mediaRecorderRef.current) {
        return mediaRecorderRef.current;
      }
      if (isMicrophoneTesting && microphoneTestRecorder) {
        return microphoneTestRecorder;
      }
      return null;
    };

    const handleNewNote = () => {
      setShowNoteInputs(!showNoteInputs);
    };

    return (
      <div className="medical-scribe-container show">
        <div className="header-controls">
          {existingNote && (
            <button className="new-note-button" onClick={handleNewNote}>
              {showNoteInputs ? "Show Note" : "New Note"}
            </button>
          )}
        </div>

        {!showNoteInputs && existingNote ? (
          <HistoricalNote
            patient={{
              id: selectedPatient?.id || "",
              name: selectedPatient?.name || "",
              breed: selectedPatient?.breed,
              appointmentTime: selectedPatient?.appointmentTime,
              appointmentType: selectedPatient?.appointmentType,
              pims_id: selectedPatient?.pims_id,
              hasNote: true,
            }}
            onClose={() => {
              setShowNoteInputs(true);
              setExistingNote(null);
            }}
          />
        ) : (
          <>
            {generatedSOAP && !existingNote && (
              <button className="toggle-inputs-button" onClick={toggleInputs}>
                {showInputs ? (
                  <>
                    <FaChevronUp /> Show Generated Note
                  </>
                ) : (
                  <>
                    <FaChevronDown /> Show Note Inputs
                  </>
                )}
              </button>
            )}
            {showInputs && (
              <div className="medical-scribe-box">
                <div className="medical-scribe-content">
                  {showTrashIcon && (
                    <div
                      className="trash-icon-container show"
                      onClick={handleDiscard}
                    >
                      <FaTrash className="trash-icon" />
                    </div>
                  )}
                  {soapMode === "spoken" && (
                    <>
                      <div className="recording-controls">
                        <div
                          className={`recording-button ${
                            isRecording ? "recording" : ""
                          } ${isGenerating ? "generating" : ""}`}
                          onClick={handleRecordingToggle}
                        >
                          {isGenerating ? (
                            <div className="centered-content">
                              <FaSpinner className="loading-icon" />
                            </div>
                          ) : isRecording ? (
                            <div className="centered-content">
                              <FaStop className="recording-icon" />
                              <span>
                                Stop <br /> Recording
                              </span>
                            </div>
                          ) : (
                            <div className="centered-content">
                              <FaMicrophone className="recording-icon" />
                              <span>
                                {hasStoppedRecording ? (
                                  <>
                                    Resume <br /> Recording
                                  </>
                                ) : (
                                  <>
                                    Start <br /> Recording
                                  </>
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                        <div
                          className={`recording-timer ${
                            isRecording || hasStoppedRecording ? "show" : ""
                          }`}
                        >
                          {formatTime(elapsedTime)}
                        </div>
                        {!isRecording && !hasStoppedRecording && (
                          <button
                            className={`microphone-test-button ${
                              isMicrophoneTesting ? "testing" : ""
                            }`}
                            onClick={handleMicrophoneTest}
                          >
                            {isMicrophoneTesting
                              ? "Stop Test"
                              : "Test Microphone"}
                          </button>
                        )}
                        {(mediaRecorderRef.current && isRecording) ||
                        (microphoneTestRecorder && isMicrophoneTesting) ? (
                          <div className="audio-visualizer-container">
                            {(() => {
                              const activeRecorder = getActiveRecorder();
                              return activeRecorder ? (
                                <LiveAudioVisualizer
                                  mediaRecorder={activeRecorder}
                                  width={200}
                                  height={50}
                                  barColor={isRecording ? "#dc3545" : "#4285f4"}
                                />
                              ) : null;
                            })()}
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                  {soapMode === "written" && (
                    <div
                      className={`written-mode-container ${
                        isGenerating ? "generating" : ""
                      }`}
                    >
                      <textarea
                        className="written-mode-input"
                        value={writtenInput}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        placeholder="Type SOAP details to start your note..."
                        disabled={isGenerating}
                      />
                      {isGenerating && (
                        <div className="written-mode-loading">
                          <FaSpinner className="loading-icon" />
                          <div className="loading-text">
                            Generating...{" "}
                            {generateProgress > 0 &&
                              `(${Math.round(generateProgress)}%)`}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={`control-buttons ${
                    showGenerateNote ? "show" : ""
                  }`}
                >
                  {soapMode === "spoken" && hasStoppedRecording && (
                    <div className="additional-details-container">
                      <textarea
                        className="additional-details-input"
                        value={additionalDetails}
                        onChange={handleAdditionalDetailsChange}
                        placeholder="Additional details..."
                      />
                    </div>
                  )}
                  <button
                    className={`generate-note-button ${
                      isLoading ? "loading" : ""
                    }`}
                    onClick={handleGenerateNote}
                    disabled={isLoading || isGenerating}
                    style={
                      {
                        "--progress": `${generateProgress}%`,
                      } as React.CSSProperties
                    }
                  >
                    <span className="button-text">
                      {isLoading || isGenerating ? (
                        <>
                          Generating...{" "}
                          {generateProgress > 0 &&
                            `(${Math.round(generateProgress)}%)`}
                        </>
                      ) : (
                        "Generate Note"
                      )}
                    </span>
                  </button>
                </div>
              </div>
            )}
            {generatedSOAP && !showInputs && (
              <div className="generated-soap-container">
                <div className="generated-soap-header">
                  <h3>Generated SOAP Note</h3>
                  <div className="save-button-container">
                    {isNoteEdited && (
                      <button
                        className="save-button"
                        onClick={handleSaveSOAP}
                        disabled={isLoading}
                      >
                        <FaSave /> Save Note
                      </button>
                    )}
                  </div>
                </div>
                <div className="generated-soap-content">
                  <textarea
                    className="generated-soap-note"
                    value={editedSOAP}
                    onChange={handleSOAPChange}
                  />
                </div>
                <div className="generated-soap-controls">
                  <button className="copy-button" onClick={handleCopyText}>
                    <FaCopy /> {copyButtonText}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

export default MedicalScribe;
