import React, { createContext, useState, useMemo } from 'react';

export const ConversationContext = createContext();

export const ConversationProvider = ({ children }) => {
  const [conversations, setConversations] = useState({
    "patient-explorer": [],
    "academic-search": [],
    "quick-soap": [],
  });

  const updateConversation = (context, conversation) => {
    setConversations(prevConversations => {
      if (JSON.stringify(prevConversations[context]) === JSON.stringify(conversation)) {
        return prevConversations;
      }
      return {
        ...prevConversations,
        [context]: conversation,
      };
    });
  };

  const contextValue = useMemo(() => ({
    conversations,
    updateConversation
  }), [conversations]);

  return (
    <ConversationContext.Provider value={contextValue}>
      {children}
    </ConversationContext.Provider>
  );
};
