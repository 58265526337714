import React from 'react';
import './ClinicPrimer.css';

const ClinicPrimer: React.FC = () => {
  return (
    <div className="clinic-primer">
      {/* Left Section */}
      <div className="clinic-primer__left-section">
        <div className="clinic-primer__top-row">
          <div className="clinic-primer__card">
            <h3>Patient Count</h3>
            {/* Patient count visualization will go here */}
          </div>
          <div className="clinic-primer__card">
            <h3>Donut Chart</h3>
            {/* Donut chart visualization will go here */}
          </div>
          <div className="clinic-primer__card">
            <h3>Horizontal Bar Chart</h3>
            {/* Bar chart visualization will go here */}
          </div>
        </div>
        
        <div className="clinic-primer__middle">
          <div className="clinic-primer__card clinic-primer__card--large">
            <h3>Clinic Primer</h3>
            {/* Main clinic data will go here */}
          </div>
        </div>

        <div className="clinic-primer__bottom">
          <div className="clinic-primer__card">
            <h3>Alerts</h3>
            {/* Alerts will go here */}
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="clinic-primer__right-section">
        <div className="clinic-primer__card">
          <h3>Heat Map</h3>
        </div>
        <div className="clinic-primer__card clinic-primer__card--large">
          <div className="clinic-primer__heatmap">
            <h4>Full Patient List</h4>
            {/* Heat map visualization will go here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicPrimer; 